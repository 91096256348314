import React from "react";
import { authenticateUser } from "../utils/auth-backend";
import { NavLink, Outlet, redirect, useLoaderData } from "react-router-dom";
import { updateUserProfilePicture } from "../utils/auth-backend";
import toast, { Toaster } from "react-hot-toast";
import Footer from "../components/fotter";
export default function Profile() {
  const user = useLoaderData();

  const [image, setImage] = React.useState(null);
  const [imageSubmitting, setImageSubmitting] = React.useState(false);

  const handleImageChange = (event) => {
    // Access the selected image file
    const selectedImage = event.target.files[0];
    const reader = new FileReader();

    // Callback function when the reader finishes reading the file
    reader.onloadend = () => {
      // Update the image state with the data URL of the selected image
      setImage(reader.result);
    };

    // If a selected image exists, read it as a data URL
    if (selectedImage) {
      reader.readAsDataURL(selectedImage);
    }
  };

  if (!user) {
    return (
      <div>
        <h1>loadding the user data </h1>
      </div>
    );
  }

  // wrte me a function that takes an image as it is saved from the data base and it return a real readable image to the src

  async function handleUpload() {
    if (!image) {
      toast.error("please select an image to upload");
    }

    setImageSubmitting(true);
    try {
      const response = await updateUserProfilePicture(
        image,
        JSON.parse(localStorage.getItem("user")).data._id
      );
      if (response.status === "success") {
        toast.success(response.message);
        // set te new user to the local storage
        const tempUser = JSON.parse(localStorage.getItem("user"));
        tempUser.data.image = image;
        localStorage.setItem("user", JSON.stringify(tempUser));
        // wait for 1 seconds then reload the page
        setTimeout(() => {
          window.location.reload();
        }, 400);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error("error uploading image");
    } finally {
      setImageSubmitting(false);
    }
  }

  const collegeImages = {
    "arab academy":
      "https://th.bing.com/th/id/R.bc640f230a734507f5c203c25f3aec34?rik=KYjEI6DtryggDw&pid=ImgRaw&r=0&sres=1&sresct=1",
    guc: "https://www.userlogos.org/files/logos/omaraty009/GUC%20LOGO.png",
    "alexandria college":
      "https://th.bing.com/th/id/OIP.0U7AMQMvWqRhgHAG9QNgBQHaHa?rs=1&pid=ImgDetMain",
    anu: "https://th.bing.com/th/id/OIP.0U7AMQMvWqRhgHAG9QNgBQHaHa?rs=1&pid=ImgDetMain",
  };

  return (
    <div>
      <div className="profile-full-container">
        <div className="user-personal-info">
          {/* <div className="user-image-container">
            {image ? (
              <img src={image} alt="user" className="user-image" />
            ) : (
              <img
                src={
                  user?.image ||
                  "https://th.bing.com/th/id/OIP.l2e1GPN32-B6Ac_EWE78nwAAAA?rs=1&pid=ImgDetMain"
                }
                alt="user"
                className="user-image"
              />
            )}
            <input
              type="file"
              onChange={handleImageChange}
              className="edit-button-image"
              placeholder="upload image"
            />
            {image && (
              <button disabled={imageSubmitting} onClick={handleUpload}>
                {imageSubmitting ? "uploading ..." : "load Image"}
              </button>
            )}
          </div> */}
          <div className="user-info">
            <div className="personal-info">
              <h1 className="user-name">
                <span className="golden-span">{user?.username}</span>
              </h1>
              <p className="user-email">{user?.email}</p>
            </div>
            <div className="college-info">
              <img
                src={collegeImages[user.schoolCollege]}
                alt="university"
                className="college-image"
              />
              <p className="major btn">{user?.education}</p>
            </div>
          </div>
          <div className="about-your-self">
            <h1>About Me ✒️</h1>
            <p>{user?.aboutYourself}</p>
          </div>
        </div>
      </div>
      <div className="created-user-courses">
        <NavLink
          to="/profile/courses"
          className={({ isActive }) =>
            isActive ? " btn btn-secondary" : "btn btn-secondary"
          }
        >
          View Created Courses
        </NavLink>
        <Outlet context={user} />
      </div>
      <Footer />
      <Toaster />
    </div>
  );
}

export function Loader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    redirect("/login?message=please login  to view your profile ");
  }
  return user.data;
}
