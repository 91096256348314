import React from "react";
import "./coursesComponents.css";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { THEME } from "@excalidraw/excalidraw";

export default function EnrolledCoursesDisplay({ enrolledCourses }) {
  const [course, setCourse] = React.useState(0);
  const randomColors = ["green", "red", "yellow", "blue"];
  function handelCourseIncrement() {
    if (course === fakeDispalyCourse.length - 1) {
      return;
    }
    setCourse((prev) => prev + 1);
  }

  function handelCourseDecrement() {
    if (course === 0) {
      return;
    }
    setCourse((prev) => prev - 1);
  }

  const fakeDispalyCourse = [
    {
      courseTitle: "Artificial Intelligence",
      courseDescription:
        "This course is about artificial intelligence and machine learning",
      theme: "bubbly",
      completed: 99,
    },
    {
      courseTitle: "Data Science",
      courseDescription:
        "This course is about data science and machine learning",
      theme: "deserty",
      completed: 39,
    },
    {
      courseTitle: "Machine Learning",
      courseDescription:
        "This course is about machine learning and deep learning",
      theme: "sparky",
      completed: 24,
    },
    {
      courseTitle: "Deep Learning",
      courseDescription:
        "This course is about deep learning and machine learning",
      theme: "bubbly",
      completed: 32,
    },
    {
      courseTitle: "Python Programming",
      courseDescription:
        "This course is about python programming and machine learning",
      theme: "sparky",
    },
    {
      courseTitle: "Web Development",
      courseDescription:
        "This course is about web development and machine learning",
      theme: "bubbly",
    },
    {
      courseTitle: "React Development",
      courseDescription:
        "This course is about react development and machine learning",
      theme: "sparky",
    },
    {
      courseTitle: "Node Development",
      courseDescription:
        "This course is about node development and machine learning",
      theme: "bubbly",
    },
    {
      courseTitle: "Express Development",
      courseDescription:
        "This course is about express development and machine learning",
      theme: "sparky",
      completed: 26,
    },
    {
      courseTitle: "Django Development",
      courseDescription:
        "This course is about django development and machine learning",
      theme: "bubbly",
      completed: 35,
    },
    {
      courseTitle: "Flask Development",
      courseDescription:
        "This course is about flask development and machine learning",
      theme: "sparky",
      completed: 60,
    },
    {
      courseTitle: "Machine Learning",
      courseDescription:
        "This course is about machine learning and deep learning",
      theme: "wavy",
      completed: 80,
    },
  ];
  return (
    <>
      <div className="coursel-container-display-courses card-body">
        <div className={`courses-container-display-courses   `}>
          <progress
            className="progress-bar-enrolled-courses progress w-56"
            value={fakeDispalyCourse[course]?.completed || 0}
            max="100"
          ></progress>
          <h1 className={`background-svg  ${fakeDispalyCourse[course].theme}`}>
            {fakeDispalyCourse[course]?.courseTitle}
          </h1>
          <p>{fakeDispalyCourse[course]?.courseDescription}</p>
          <p style={{ display: "block" }} className="completed-percentage">
            {fakeDispalyCourse[course].completed || 0} % completed
          </p>
          <hr />
        </div>
        <div className="bottom-part-display-enrolled-courses-home">
          <button
            onClick={handelCourseDecrement}
            className={`${
              course === 0 && "disabled"
            }  btn btn-circle coursel-container-display-button`}
          >
            <FaArrowLeft />
          </button>
          <div className="course-number-display">
            <p>
              {course + 1} / {fakeDispalyCourse.length}
            </p>
          </div>
          <button
            className={`${
              fakeDispalyCourse.length - 1 === course && "disabled"
            } coursel-container-display-button btn btn-circle   `}
            onClick={handelCourseIncrement}
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </>
  );
}
