export async function handleSavingSummary(summary) {
  // get all the summaries from the local storage and append on them the new summary and then submit them again
  // add the date to the summary
  summary.date = new Date().toLocaleDateString();
  try {
    const summaries = JSON.parse(localStorage.getItem("summaries")) || [];
    const newSummaries = [...summaries, summary];
    localStorage.setItem("summaries", JSON.stringify(newSummaries));
    return { message: "Summary saved successfully" };
  } catch (error) {
    console.log(error);
    return { error: "An error occurred" };
  }
}

export async function GetAllSketches() {
  try {
    const sketches = JSON.parse(localStorage.getItem("sketches")) || [];
    return { sketches };
  } catch (error) {
    console.log(error);
    return { error: "An error occurred" };
  }
}
