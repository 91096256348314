import { NavLink, useLoaderData } from "react-router-dom";
import React from "react";
export default function NewSketchMeta() {
  const user = useLoaderData();
  const [sketch, setSketch] = React.useState({
    title: "",
    sketchDescription: "",
    category: "biology",
    email: user.data.email,
    date: new Date().toLocaleDateString(),
  });

  const handleChange = (e) => {
    setSketch({ ...sketch, [e.target.name]: e.target.value });
  };
  return (
    <div className="new-summary-steps-pages">
      <h1 className="summary-step-title">
        give your sketch a{" "}
        <span className="golden-span">title & description</span>
      </h1>
      <p>description will help people in the community to find your note</p>
      <div className="step-one-container">
        <img
          src="https://th.bing.com/th/id/OIG4.mGXGwjcs5XdWCI_gZtQa?w=1024&h=1024&rs=1&pid=ImgDetMain"
          alt="summary image"
          className="steps-summary-images"
        />
        <div className="input-summary-step-one-fields">
          <div>
            <h1 className="meta-data-titles">Sketch title</h1>
            <input
              type="input"
              className="input  input-bordered w-full max-w-xs"
              placeholder="ex: mitosis sketch"
              name="title"
              onChange={handleChange}
            />
          </div>
          <div>
            <h1 className="meta-data-titles">sketch description</h1>
            <textarea
              className="textarea textarea-bordered w-full max-w-xs"
              placeholder="ex: give me only the rules of the chapter"
              name="sketchDescription"
              onChange={handleChange}
            ></textarea>
          </div>
          <div>
            <h1 className="meta-data-titles">category</h1>
            <select className="select" name="category" onChange={handleChange}>
              <option value="biology">biology</option>
              <option value="physics">physics</option>
              <option value="chemistry">chemistry</option>
              <option value="math">math</option>
              <option value="programming">programming</option>
            </select>
          </div>
          <NavLink
            to="/sketch/board"
            className="btn btn-primary btn-lg btn-block mt-4"
            state={sketch}
            id="next-step"
          >
            start sketching
          </NavLink>
        </div>
      </div>
    </div>
  );
}
