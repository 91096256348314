import React from "react";
import Draggable from "react-draggable";
import "./testing.css";
import YouTube from "react-youtube";

export default function DraggabelResizableComponent({
  youtubeVedios,
  setYoutubeVedios,
}) {
  function handleDragChange(e, ui, index) {
    const { x, y } = youtubeVedios[index].position;
    youtubeVedios[index].position = { x: x + ui.deltaX, y: y + ui.deltaY };
    setYoutubeVedios([...youtubeVedios]);
  }

  return (
    <div className="testing-dragable-container">
      {youtubeVedios.map((item, index) => (
        <Draggable
          key={item.id}
          position={item.position}
          onDrag={(e, ui) => handleDragChange(e, ui, index)}
          handle=".handle"
        >
          <div className="handle">
            <div className="youtube-draggables-options">
              <input
                type="text"
                placeholder="title"
                className="youtube-draggable-title input"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setYoutubeVedios((prev) => {
                      const newVedios = [...prev];
                      newVedios[index].title = e.target.value;
                      return newVedios;
                    });
                  }
                }}
              />
              <button
                onClick={() => {
                  setYoutubeVedios((prev) => {
                    const newVedios = [...prev];
                    newVedios.splice(index, 1);
                    return newVedios;
                  });
                }}
                className="youtube-draggable-delete-button"
              >
                X
              </button>
            </div>
            <div className="youtube-draggable">
              <YouTube videoId={item.id} />
            </div>
          </div>
        </Draggable>
      ))}
    </div>
  );
}
