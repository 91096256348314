import React from "react";
import {
  useLoaderData,
  defer,
  Await,
  useNavigate,
  redirect,
  useParams,
} from "react-router-dom";
import { getSketchById } from "../../utils/sketch-Backend";
import { Suspense } from "react";
import { Excalidraw } from "@excalidraw/excalidraw";
import { FiMaximize2 } from "react-icons/fi";
import { CiMinimize1 } from "react-icons/ci";
import toast, { Toaster } from "react-hot-toast";
import { addNewSketch } from "../../utils/sketch-Backend";

export default function ViewSketch() {
  const [excalidrawAPI, setExcalidrawAPI] = React.useState(null);
  const [maximized, setMaximized] = React.useState(false);
  const [newSketch, setNewSketch] = React.useState(null);
  const [saved, setSaved] = React.useState(false);

  const [email, setEmail] = React.useState("");

  const myParams = useParams();
  // console.log(myParams.id);
  const navigate = useNavigate();

  const data = useLoaderData();

  function handleChangeInSketch(e) {
    setNewSketch((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setEmail(user.data.email);
    }
  }, []);
  async function handleSavingDraft() {
    if (!email) {
      toast.error("please login to save the sketch");
      setTimeout(() => {
        navigate(`/login?sketchData=${myParams.id}`);
      }, 1000);
      return;
    }
    const data = {
      ...newSketch,
      email: email,
      mySketch: excalidrawAPI?.getSceneElements() || newSketch.mySketch,
    };
    try {
      setSaved(true);
      console.log(data);
      const response = await addNewSketch(data);
      if (response.status === "success") {
        toast.success(response.message);
        setTimeout(() => {
          navigate("/sketch");
        }, 1000);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.log(error);
      toast.error("error saving the sketch");
    } finally {
      setSaved(false);
    }
  }
  return (
    <div
      className="view-sketch-mode-container"
      id={saved ? "container-submiting" : ""}
    >
      <Suspense
        fallback={
          <span className="loading-in-middle loading loading-infinity loading-lg"></span>
        }
      >
        <Await resolve={data.sketches}>
          {(sketch) => {
            if (!sketch[0]) return <div>no sketch found</div>;
            delete sketch[0]._id;

            setNewSketch(sketch[0]);
            return (
              <div className="view-sketch-container">
                {!maximized && (
                  <div className="input-view-fields">
                    <input
                      type="text"
                      value={sketch[0]?.title}
                      className="view-title-input-field input input-bordered"
                      onChange={handleChangeInSketch}
                      name="title"
                      disabled
                      id="view-title-input-field"
                    />
                    <textarea
                      value={sketch[0]?.sketchDescription}
                      className="view-text-area-data textarea textarea-bordered"
                      onChange={handleChangeInSketch}
                      name="sketchDescription"
                      disabled
                    ></textarea>
                    <input
                      type="text"
                      value={sketch[0]?.category}
                      disabled
                      className="view-category-input-field input input-bordered "
                    />
                    <p>
                      changes you will make will not be saved save to draft
                      first
                    </p>
                  </div>
                )}
                <div
                  style={{
                    width: maximized ? "100vw" : "80vw",
                    height: maximized ? "100vh" : "80vh",
                    borderRadius: "10px",
                    overflow: "hidden",
                    zIndex: 1,
                    marginBottom: maximized ? "0px" : "100px",
                  }}
                  className="excalidraw-container"
                >
                  <Excalidraw
                    excalidrawAPI={(api) => setExcalidrawAPI(api)}
                    initialData={{
                      elements: sketch[0]?.mySketch,
                      appState: {
                        zenModeEnabled: false,
                        viewBackgroundColor: "#ffffff",
                        zoom: 1,
                      },
                      scrollToContent: true,
                    }}
                    renderTopRightUI={() => (
                      <>
                        <button
                          onClick={() => setMaximized(!maximized)}
                          className="maxmizing-button-view-sketch btn"
                        >
                          {maximized ? <CiMinimize1 /> : <FiMaximize2 />}
                        </button>
                        <button
                          className="save-button-view-sketch btn"
                          onClick={() => handleSavingDraft()}
                        >
                          save draft
                        </button>
                      </>
                    )}
                  />
                </div>
              </div>
            );
          }}
        </Await>
      </Suspense>
      <Toaster />
    </div>
  );
}

export function Loader({ params }) {
  const { id } = params;
  const sketchData = getSketchById(id);
  return defer({ sketches: sketchData });
}
