import React from "react";
import { useLocation, useNavigate } from "react-router";
import { MDXEditor } from "@mdxeditor/editor";
import {
  headingsPlugin,
  ChangeCodeMirrorLanguage,
  ConditionalContents,
  InsertCodeBlock,
  InsertSandpack,
  ShowSandpackInfo,
  codeBlockPlugin,
  codeMirrorPlugin,
  sandpackPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  BlockTypeSelect,
  CodeToggle,
  DiffSourceToggleWrapper,
  diffSourcePlugin,
  InsertImage,
  imagePlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import ProgressBar from "../../components/progressBar";
import { handleSavingSummary } from "../../utils/backend";
import toast, { Toaster } from "react-hot-toast";

const defaultSnippetContent = `
export default function App() {
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
    </div>
  );
}
`.trim();

const simpleSandpackConfig = {
  defaultPreset: "react",
  presets: [
    {
      label: "React",
      name: "react",
      meta: "live react",
      sandpackTemplate: "react",
      sandpackTheme: "light",
      snippetFileName: "/App.js",
      snippetLanguage: "jsx",
      initialSnippetContent: defaultSnippetContent,
    },
  ],
};

export default function MarkDownFinallStep() {
  const { state } = useLocation();
  const [summary, setSummary] = React.useState({
    ...state,
    humanChange: "",
  });
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const fakeMarkdown = summary.data || `# put your markdown here`;
  function handleHumanChange(e) {
    setSummary((prev) => ({ ...prev, humanChange: e }));
  }
  async function imageUploadHandler(image) {
    const formData = new FormData();
    formData.append("image", image);

    const response = await fetch("/uploads/new", {
      method: "POST",
      body: formData,
    });

    const json = await response.json();
    // append the json url to the markdown
    setSummary((prev) => ({
      ...prev,
      humanChange: `${prev.humanChange}\n![${json.url}](${json.url})`,
    }));
    return json.url;
  }

  async function handleSubmit() {
    setSubmitting(true);
    const response = await handleSavingSummary(summary);
    if (response.error) {
      toast.error("An error occurred");
    } else {
      toast.success("Summary saved successfully");
      setTimeout(() => {
        navigate("/summary");
      }, 1000);
    }
    setSubmitting(false);
  }

  return (
    <div
      className="new-summary-steps-pages"
      id={submitting ? "container-submiting" : ""}
    >
      <div className="my-proggressBar">
        <ProgressBar progress={99} color={"success"} />
      </div>
      <h1 className="summary-step-title">
        step three : <span className="golden-span">edit summary</span>
      </h1>
      <p>you can always edit it later</p>
      <div className="step-three-container">
        <MDXEditor
          markdown={fakeMarkdown}
          plugins={[
            toolbarPlugin({
              toolbarContents: () => (
                <ConditionalContents
                  options={[
                    {
                      when: (editor) => editor?.editorType === "codeblock",
                      contents: () => <ChangeCodeMirrorLanguage />,
                    },
                    {
                      when: (editor) => editor?.editorType === "sandpack",
                      contents: () => <ShowSandpackInfo />,
                    },
                    {
                      fallback: () => (
                        <DiffSourceToggleWrapper>
                          <UndoRedo />
                          <BoldItalicUnderlineToggles />
                          <BlockTypeSelect />
                          <CodeToggle />
                          <InsertImage />
                          <InsertCodeBlock />
                          <InsertSandpack />
                        </DiffSourceToggleWrapper>
                      ),
                    },
                  ]}
                />
              ),
            }),
            headingsPlugin(),
            listsPlugin(),
            quotePlugin(),
            thematicBreakPlugin(),
            diffSourcePlugin({
              diffMarkdown: summary.data || `# put your markdown here`,
              viewMode: "diff",
            }),
            codeBlockPlugin({ defaultCodeBlockLanguage: "js" }),
            sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
            codeMirrorPlugin({
              codeBlockLanguages: { js: "JavaScript", css: "CSS" },
            }),
            imagePlugin({ imageUploadHandler }),
          ]}
          directiveDescriptors={[]}
          onChange={(e) => {
            handleHumanChange(e);
          }}
          className="editor-container"
        />
      </div>
      <button
        className={`btn ${submitting && "btn-disabled"}`}
        id="save-data"
        onClick={handleSubmit}
        disabled={submitting}
      >
        {submitting ? "saving ..." : "save summary"}
      </button>
      <Toaster />
    </div>
  );
}
