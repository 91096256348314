import React from "react";
import YouTube from "react-youtube";
import { getYouTubeVideoId } from "../../utils/commonUtils";
import { useLocation } from "react-router";
export default function VedioDisplayPage() {
  const location = useLocation();
  console.log(location.state, "vedio display page");
  return (
    <div className="course-vedio-display-container">
      <div className="vedio-display-container"></div>
    </div>
  );
}
