import React from "react";
import hubImage from "../../assets/hub-sketsh.svg";
export default function SketchHub() {
  return (
    <div className="sketch-hub-full-container">
      <h1>
        <span className="sketch-hub-title text-blue-500"> SKETCH HUB 🌍</span>
      </h1>
      <img src={hubImage} alt="hub" className="sketch-hub-image" />
    </div>
  );
}
