import React from "react";
import { Excalidraw } from "@excalidraw/excalidraw";
import "./sketch.css";
import { useLocation, useLoaderData, useNavigate } from "react-router-dom";
import { sketchBoilderPlate } from "./boilerPlates.js";
import { FiMaximize2 } from "react-icons/fi";
import { CiMinimize1 } from "react-icons/ci";
import { addNewSketch } from "../../utils/sketch-Backend.js";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import DraggabelResizableComponent from "../../components/draggabelResizableComponent.jsx";
import { getYouTubeVideoId } from "../../utils/commonUtils.js";
export default function Sketch() {
  const navigate = useNavigate();
  const [excalidrawAPI, setExcalidrawAPI] = React.useState(null);
  const [youtubeVedios, setYoutubeVedios] = React.useState([]);
  const user = useLoaderData();
  console.log(user);
  const sketch = useLocation().state;
  const [mySketch, setmySketch] = React.useState({
    ...sketch,
    mySketch: sketchBoilderPlate,
    youtubeVedios: [],
  });
  const [saved, setSaved] = React.useState(false);
  const [maximized, setMaximized] = React.useState(false);
  const monileRef = React.useRef(null);
  function handleMaximize() {
    setMaximized(!maximized);
  }
  const sketchRef = React.useRef(null);

  if (maximized) {
    console.log(sketchRef.current);
    sketchRef.current.focus();
  }

  async function handleChanegInSketch() {
    try {
      setSaved(true);
      const newSketch = {
        ...mySketch,
        mySketch: excalidrawAPI?.getSceneElements(),
      };
      setmySketch(newSketch);
      const response = await addNewSketch(newSketch);
      if (response.status === "success") {
        toast.success(response.message);
        setTimeout(() => {
          navigate("/sketch");
        }, 1000);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.log(error);
      toast.error("error saving the sketch");
    } finally {
      setSaved(false);
    }
  }

  function addYoutubeDraggable(url) {
    const videoId = getYouTubeVideoId(url);
    if (videoId) {
      setYoutubeVedios((prev) => [
        ...prev,
        {
          id: videoId,
          url,
          position: { x: 0, y: 0 },
        },
      ]);
      setmySketch((prev) => ({
        ...prev,
        youtubeVedios: [
          ...prev.youtubeVedios,
          {
            id: videoId,
            url,
            position: { x: 0, y: 0 },
          },
        ],
      }));
    } else {
      toast.error("Invalid youtube url");
    }
  }
  function handleVedioSelects(e) {
    const videoId = e.target.value;
    const url = `https://www.youtube.com/watch?v=${videoId}`;
    addYoutubeDraggable(url);
  }
  return (
    <div className="sketch-container" id={saved ? "container-submiting" : ""}>
      {!maximized && (
        <>
          <h1>{sketch?.title}</h1>
          <p className="category-of-sketch btn">{sketch.category}</p>
          <Link to="/sketch" className="link-to-all-sketches link  link-hover">
            back to all sketches
          </Link>
          <div className="youtube-input-field">
            <input
              type="text"
              placeholder="enter youtube link"
              className=" input"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  addYoutubeDraggable(e.target.value);
                  e.target.value = "";
                }
              }}
            />
            <select onChange={handleVedioSelects} className="select">
              {mySketch.youtubeVedios.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.title || item.id}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
      <div
        style={{
          width: maximized ? "100vw" : "80vw",
          height: maximized ? "100vh" : "80vh",
          borderRadius: "10px",
          overflow: "hidden",
          zIndex: 1,
          marginBottom: maximized ? "0px" : "100px",
        }}
        className="excalidraw-container"
        ref={sketchRef}
      >
        <Excalidraw
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          initialData={{
            elements: sketchBoilderPlate,
            appState: { zenModeEnabled: false, viewBackgroundColor: "#ffffff" },
            scrollToContent: true,
          }}
          onChange={(elements, state) => {}}
          renderTopRightUI={() => {
            return (
              <>
                <button className="maximizing-button " onClick={handleMaximize}>
                  {maximized ? <CiMinimize1 /> : <FiMaximize2 />}
                </button>
                <button
                  className="save-button"
                  onClick={() => handleChanegInSketch()}
                >
                  Save
                </button>
              </>
            );
          }}
        />
      </div>
      <Toaster />
      <DraggabelResizableComponent
        youtubeVedios={youtubeVedios}
        setYoutubeVedios={setYoutubeVedios}
      />
    </div>
  );
}
