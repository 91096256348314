import React from "react";
import ProgressBar from "../../../components/progressBar";
import { useLocation, useNavigate } from "react-router";
import { createUser } from "../../../utils/auth-backend";
import toast, { Toaster } from "react-hot-toast";
export default function SignUpStepTwo() {
  const navigate = useNavigate();
  const [submitting, setSubmiting] = React.useState(false);
  const data = useLocation();
  if (!data.state) {
    navigate("/signup");
  }
  const [myData, setMyData] = React.useState({
    ...data.state,
    education: "high school",
    schoolCollege: "arab academy",
    aboutYourself: "",
  });
  function handleChange(event) {
    setMyData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  async function handleSignUp() {
    setSubmiting(true);

    try {
      const response = await createUser(myData);
      if (response.status === "error") {
        toast.error(response.error);
        setSubmiting(false);
        return;
      }
      console.log(response);
      navigate("/login", {
        state: { message: "account created successfully" },
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmiting(false);
    }
  }
  return (
    <div
      className="sign-up-step-one"
      id={submitting ? "container-submiting" : ""}
    >
      <ProgressBar progress={95} />
      <h1>your eduction</h1>
      <p>let us know a bit more about you</p>
      <div className="my-signup-two-selects">
        <div className="label-signup-select">
          <label htmlFor="education">education</label>
          <select
            name="education"
            id="education"
            className="select"
            value={myData.education}
            onChange={handleChange}
          >
            <option value="high school">high school</option>
            <option value="bachelor">bachelor</option>
            <option value="master">master</option>
            <option value="phd">phd</option>
          </select>
        </div>
        <div className="label-signup-select">
          <label htmlFor="school/college">school/college</label>
          <select
            name="schoolCollege"
            id="school/college"
            className="select"
            value={myData.schoolCollege}
            onChange={handleChange}
          >
            <option value="arab academy">Arab Academy</option>
            <option value="guc">GUC</option>
            <option value="alexandria college">alexandria college</option>
            <option value="anu">ANU</option>
          </select>
        </div>
      </div>
      <label htmlFor="yourself">tell us about yourself</label>
      <textarea
        name="aboutYourself"
        id="yourself"
        cols="30"
        rows="10"
        className="about-your-self textarea textarea-bordered"
        value={myData.yourSelf}
        onChange={handleChange}
        placeholder="this will help the ai personalize the notes for you and give you the best experience possible"
      ></textarea>
      <button
        className="add-user-button btn btn-primary"
        onClick={handleSignUp}
        disabled={submitting}
        id={submitting ? "submiting" : ""}
      >
        {submitting ? "submiting" : "sign up"}
      </button>
    </div>
  );
}
