import { Outlet, useLocation, useParams } from "react-router";
import "../App.css";
import React from "react";
export default function Wrapper() {
  const [theme, setTheme] = React.useState("luxury");

  return (
    <div data-theme={theme} className="all-pages-wrapper">
      <Outlet context={{ theme, setTheme }} />
    </div>
  );
}
