import React from "react";
import { useLoaderData, Link, NavLink } from "react-router-dom";
import heart from "./heart.svg";
import { Await, defer } from "react-router-dom";
import { Suspense } from "react";
import { getDataForUser } from "../../utils/sketch-Backend";
import { Excalidraw } from "@excalidraw/excalidraw";
import { redirect } from "react-router-dom";
import { AiOutlineBars } from "react-icons/ai";
import { PiDotsThreeOutlineVertical } from "react-icons/pi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { handleDeleteSketch } from "../../utils/sketch-Backend";
import { AiOutlineGlobal } from "react-icons/ai";
export default function AllSketches() {
  const data = useLoaderData();
  const [copiedSketchId, setCopiedSketchId] = React.useState(null);
  const [deleteSketch, setDeleteSketch] = React.useState(false);
  // const [mySketches, setMySketches] = React.useState(null);
  function handleCopyLink(id) {
    setCopiedSketchId(id);
    toast.success("link copied to clipboard");
  }

  async function handleDelete(id, index) {
    setDeleteSketch(true);
    try {
      const response = await handleDeleteSketch(id);
      if (response.status === "success") {
        toast.success(response.message);
        // remove the sketch from the list by the index
        // setMySketches((prev) => {
        //   const newSketches = [...prev];
        //   newSketches.splice(index, 1);
        //   console.log(newSketches, "new sketches");
        //   return newSketches;
        // });
        // reload the page after 500ms
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.log(error);
      toast.error("error deleting the sketch");
    } finally {
      setDeleteSketch(false);
    }
  }
  const link = "https://platinumai.tech";
  return (
    <div
      className="all-sketches-page"
      id={deleteSketch ? "container-submiting" : ""}
    >
      <h1>All Sketches</h1>
      <p>
        changes you will make in this sketches will not be saved go to edit mode
        first
      </p>
      <NavLink to="/sketch/hub" className="sketch-hub-link btn">
        Sketch Hub <AiOutlineGlobal />
      </NavLink>
      <div className="sketches-container">
        <Suspense fallback={<div>Loading...</div>}>
          <Await resolve={data.sketches}>
            {(sketches) => {
              if (!sketches) return <div>no sketches created yet</div>;
              // setMySketches(sketches);
              if (!sketches.map)
                return <div>check your internet connection</div>;
              return sketches?.map((sketch, index) => (
                <div className="sketch-conatiner-in-all-risks" key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h2>{sketch.title}</h2>
                    <details className="dropdown dropdown-end">
                      <summary className="m-1 btn">
                        <PiDotsThreeOutlineVertical />
                      </summary>
                      <ul className="p-2 shadow menu dropdown-content z-[100] bg-white rounded-box w-40">
                        <li className="option-on-sketch-all-pages">
                          <NavLink
                            to={`/sketch/update/${sketch._id.toString()}`}
                            state={sketch}
                            className="share-button"
                          >
                            Edit
                          </NavLink>
                        </li>
                        <li className="option-on-sketch-all-pages z-20">
                          <CopyToClipboard
                            text={`${link}/#/view/sketch/${sketch._id.toString()}`}
                            onCopy={() => handleCopyLink(sketch._id)}
                          >
                            <button className="share-button btn-outline btn-success">
                              Share
                            </button>
                          </CopyToClipboard>
                        </li>
                        <li className="z-20">
                          <button
                            className="delete-button btn btn-error btn-error"
                            id="delete-button"
                            onClick={() => {
                              handleDelete(sketch._id.toString(), index);
                            }}
                          >
                            <MdDelete />
                          </button>
                        </li>
                      </ul>
                    </details>
                  </div>
                  <Link
                    to={`/sketch/update/${sketch._id.toString()}`}
                    key={index}
                    className="sketch-card"
                    state={sketch}
                  >
                    <Excalidraw
                      initialData={{
                        elements: sketch.mySketch,
                        appState: {
                          zenModeEnabled: false,
                          viewBackgroundColor: "#ffffff",
                          zoom: 1,
                        },
                        scrollToContent: true,
                      }}
                    />
                  </Link>
                </div>
              ));
            }}
          </Await>
        </Suspense>

        <Link to="/new/sketch" className="new-sketch-card">
          <img src={heart} alt="heart" className="heart-svg" />
          <h2>new sketch</h2>
        </Link>
      </div>
      <Toaster />

      <dialog id="delete-pop-up-modal" className="modal">
        <div className="modal-box">
          <h2>Are you sure you want to delete this sketch</h2>
          <button
            className="btn btn-error"
            onClick={() => {
              handleDelete();
            }}
          >
            {deleteSketch ? "deleting..." : "delete"}
          </button>
          <button
            className="btn btn-outline"
            onClick={() => {
              document.getElementById("delete-pop-up-modal").close();
            }}
          >
            cancel
          </button>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
}

export function loader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    throw new redirect("/login?message=please login to view sketches");
  }
  const response = getDataForUser(user.data.email);
  return defer({ sketches: response });
}
