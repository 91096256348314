import { useLoaderData, redirect } from "react-router";
import { NavLink } from "react-router-dom";
import "./summary.css";
import { MdSummarize } from "react-icons/md";
import { GoPlus } from "react-icons/go";
export default function AllSummary() {
  const myData = useLoaderData();
  return (
    <div className="summary-page-container">
      <h1>
        Welcome to <span className="golden-span">Platinum Ai</span> Summaries
      </h1>
      <div className="my-summaries">
        <NavLink
          to="/summary/new"
          className="create-new-summary btn"
          id="create-new-summary"
        >
          <MdSummarize /> <GoPlus />
        </NavLink>
        {myData.length === 0 ? (
          <div className="empty-summary">No summaries created Yet </div>
        ) : (
          myData.map((summary, index) => (
            <div className="summary-card" key={index}>
              <div className="summary-card-meta-data">
                <h3>{summary.title}</h3>
                <p>Date: {summary.date}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export function Loader() {
  // get the user from the local storage if not there direct to the login page
  const user = localStorage.getItem("user");
  if (!user) {
    throw new redirect("/login?message=please login to view summaries");
  }
  const data = localStorage.getItem("summaries") || "[]";
  const summaries = JSON.parse(data);
  return summaries;
}
