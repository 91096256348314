import React from "react";
import { addingSectionToCourse } from "../../utils/courses-backend";
import toast, { Toaster } from "react-hot-toast";

export default function AddSectionModal({ courseId, setCourseData }) {
  const [newSection, setNewSection] = React.useState({
    sectionName: "",
    sectionDescription: "",
  });
  const [submitted, setSubmitted] = React.useState(false);
  console.log(courseId);
  function handleSectionChange(e) {
    setNewSection({ ...newSection, [e.target.name]: e.target.value });
  }

  async function handleAddingSection() {
    // addingSectionToCourse(courseId, newSection);
    try {
      setSubmitted(true);
      const addedSection = await addingSectionToCourse(newSection, courseId);
      if (addedSection.status === "success") {
        toast.success("Section Added Successfully");
        setCourseData((prev) => {
          const newSections = [...prev.sections];
          newSections.push(newSection);
          return { ...prev, sections: newSections };
        });
        setNewSection({
          sectionName: "",
          sectionDescription: "",
        });
      } else {
        toast.error("Error Adding Section");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitted(false);
    }
  }
  return (
    <dialog id="add-section" className="adding-section-modal modal">
      <div className="modal-box ">
        <div className="modal-action">
          <form method="dialog">
            {/* if there is a button, it will close the modal */}
            <button className="btn">Close</button>
          </form>
        </div>
        <div className="modal-header">
          <h1>Add Section</h1>
        </div>
        <div className="adding-section-modal-form">
          <input
            type="text"
            placeholder="Section Name"
            className="input input-bordered"
            name="sectionName"
            onChange={handleSectionChange}
            value={newSection.sectionName}
          />
          <textarea
            placeholder="Section Description"
            className="textarea textarea-bordered"
            rows="3"
            name="sectionDescription"
            onChange={handleSectionChange}
            value={newSection.sectionDescription}
          />
          <button
            onClick={handleAddingSection}
            className="btn btn-primary btn-block"
            disabled={submitted}
          >
            {submitted ? "Adding..." : "Add Section"}
          </button>
        </div>
      </div>
      <Toaster />
    </dialog>
  );
}
