import React from "react";

export default function DisplaySingleCourse({ course }) {
  const collegeImages = {
    "arab academy":
      "https://th.bing.com/th/id/R.bc640f230a734507f5c203c25f3aec34?rik=KYjEI6DtryggDw&pid=ImgRaw&r=0&sres=1&sresct=1",
    guc: "https://www.userlogos.org/files/logos/omaraty009/GUC%20LOGO.png",
    "alexandria college":
      "https://th.bing.com/th/id/OIP.0U7AMQMvWqRhgHAG9QNgBQHaHa?rs=1&pid=ImgDetMain",
    anu: "hhttps://th.bing.com/th/id/OIP.0U7AMQMvWqRhgHAG9QNgBQHaHa?rs=1&pid=ImgDetMain",
  };
  return (
    <div className="single-course-container card  bg-base-100 shadow-xl glass">
      <figure className="px-10 pt-10">
        <h1>{course?.courseName}</h1>
      </figure>
      <div
        className="card-body items-center text-center"
        style={{
          marginTop: "-20px",
        }}
      >
        <h2 className="creator-name-courses-display-filtered card-title link">
          {course?.creator}
        </h2>
        <div className="image-with-course-description">
          <img
            src="https://th.bing.com/th/id/R.bc640f230a734507f5c203c25f3aec34?rik=KYjEI6DtryggDw&pid=ImgRaw&r=0&sres=1&sresct=1"
            alt="college"
            className="college-image"
          />
          <p
            style={{
              textAlign: "left",
            }}
          >
            {course.courseDescription}
          </p>
          <p className="pricing-courses-display">free</p>
        </div>
        <p className="courses-major-display-filtered btn btn-square">
          {course.major}
        </p>
      </div>
    </div>
  );
}
