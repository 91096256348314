import React from "react";
import "./userCourses.css";
import DisplaySingleCourse from "../../components/DisplaySingleCourse";
import { getUserCourses } from "../../utils/courses-backend";
import { defer, Await, useLoaderData, useOutletContext } from "react-router";
import { Suspense } from "react";
import { NavLink } from "react-router-dom";
import { BSON } from "realm-web";
export default function UserCreatedCourses() {
  const courses = useLoaderData();
  const user = useOutletContext();

  return (
    <div className="user-created-courses">
      <h1>Your Created Courses</h1>
      <div className="user-own-courses">
        <Suspense fallback={<div>Loading...</div>}>
          <Await resolve={courses.courses}>
            {(courses) => {
              console.log(courses, "user created courses");
              if (courses.status !== "success") {
                return <h1>failed to load your courses</h1>;
              }

              return (
                <div className="courses-container">
                  {courses.courses?.map((course, index) => {
                    return (
                      <NavLink
                        to={`/courses/edit/${BSON.ObjectId(course._id)}`}
                        className="course-card"
                        key={index}
                        state={{ course, user }}
                      >
                        <DisplaySingleCourse course={course} />
                      </NavLink>
                    );
                  })}
                </div>
              );
            }}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}

export function loader() {
  const userCourses = getUserCourses("abdelrahmangasser2023@gmail.com");

  return defer({ courses: userCourses });
}
