export default function ProgressBar({ progress, color }) {
  function getProgressColor() {
    if (progress < 30) {
      return "progress-primary";
    } else if (progress < 90) {
      return "progress-warning";
    } else {
      return "progress-success";
    }
  }
  return (
    <div className="progress-bar">
      <progress
        className={`progress ${getProgressColor()}`}
        value={progress || 0}
        max="100"
      ></progress>
    </div>
  );
}
