import React from "react";
import ProgressBar from "../../components/progressBar";
import { useLocation, useNavigate } from "react-router-dom";
import { fakeSubjects } from "../summaryPages/fakeData";

export default function ExamMeta() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const [exam, setExam] = React.useState({
    subject: "",
    chapters: [],
    description: "",
    exam: {
      title: "Electricity Exam",
      questions: [
        {
          question: "What is the SI unit of electric charge?",
          answer: "Coulomb",
          options: ["Coulomb", "Ohm", "Volt", "Ampere"],
        },
        {
          question: "What is Ohm's Law?",
          answer: "V = IR",
          options: ["V = IR", "P = IV", "I = VR", "R = VI"],
        },
        {
          question:
            "What is the relationship between current, voltage, and resistance in a series circuit?",
          answer: "V = IR",
          options: ["V = I/R", "I = V/R", "R = V/I", "V = IR"],
        },
        {
          question: "What is the unit of electric current?",
          answer: "Ampere",
          options: ["Ampere", "Coulomb", "Ohm", "Volt"],
        },
        {
          question:
            "What is the formula for calculating power in an electrical circuit?",
          answer: "P = VI",
          options: ["P = IV", "P = VI", "P = V/I", "P = I/V"],
        },
      ],

      textQuestions: [
        {
          question:
            "What is the formula for calculating power in an electrical circuit?",
          answer: "P = VI",
        },
        {
          question:
            "What is the formula for calculating power in an electrical circuit?",
          answer: "P = VI",
        },
        {
          question: "what is the favorite",
          answer: "P = VI",
        },
        {
          question: "jomana emad ",
          answer: "P = VI",
        },
      ],
    },
  });
  function handleAddingChapters(event) {
    const { value } = event.target;
    // check if the value is checked or not
    if (event.target.checked) {
      // add the chapter to the chapters array
      setExam({ ...exam, chapters: [...exam.chapters, value] });
    } else {
      // remove the chapter from the chapters array
      setExam({
        ...exam,
        chapters: exam.chapters.filter((chapter) => chapter !== value),
      });
    }
  }
  // Function to handle changes in subject selection
  const handleSubjectChange = (event) => {
    // Update the state with the selected subject
    setExam({ ...exam, subject: event.target.value, chapters: [] });
  };

  function handleDescriptionChange(e) {
    setExam((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  async function handleSubmit() {
    // simulate a submit for 2 seconds
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      navigate("/exam/solve", { state: exam });
    }, 2000);
  }

  return (
    <div
      className="new-exam-page-meta new-summary-steps-pages"
      id={submitting ? "container-submiting" : ""}
    >
      <div className="my-proggressBar">
        <ProgressBar progress={50} color={"warning"} />
      </div>
      <h1 className="summary-step-title">
        <span className="golden-span">Specify your Exam</span>
      </h1>

      <textarea
        placeholder="specify how your exam should be like ex : make it hard as possible and focus on rules based questions"
        name="description"
        value={exam.description}
        onChange={handleDescriptionChange}
        className="text-area-exam-description textarea textarea-bordered"
      />
      <div className="step-two-container">
        <div className="radio-button-container">
          <h1 className="step-two-meta-data-titles">subject</h1>
          {Object.keys(fakeSubjects).map((subject, index) => (
            <label key={index} className="radio-label">
              <input
                type="radio"
                name="subject"
                value={subject}
                onChange={handleSubjectChange}
                checked={exam.subject === subject}
                className="radio-input"
              />
              <span className="radio-text">{subject}</span>
            </label>
          ))}
        </div>
        <span className="loading-in-middle loading loading-infinity loading-lg"></span>
        <div className="chapters-checkboxes radio-button-container">
          <h1 className="step-two-meta-data-titles">chapters</h1>
          {exam.subject &&
            fakeSubjects[exam.subject].chapterNames.map((subject, index) => (
              <label key={subject} className="radio-label">
                <input
                  type="checkbox"
                  name="chapters"
                  value={subject}
                  onChange={handleAddingChapters}
                  className="checkbox checkbox-warning"
                />
                <span className="check-box-text">{subject}</span>
              </label>
            ))}
          {exam.chapters.length > 0 && (
            <button
              className={`btn ${submitting && "btn-disabled"}`}
              id="next-step"
              onClick={handleSubmit}
              disabled={submitting}
            >
              {submitting ? "Generating ... " : "Generate Exam"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
