import React from "react";
import { redirect, useLocation } from "react-router";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import AddSectionModal from "./addSectionModal";
import { BSON } from "realm-web";
import { NavLink } from "react-router-dom";
export default function EditUserCourses() {
  const location = useLocation();

  const { course, user } = location?.state;
  const [courseData, setCourseData] = React.useState(course);

  const fakeVedios = [
    {
      videoName: "Video 1",
      videoDescription: "This is video 1",
    },
    {
      videoName: "Video 2",
      videoDescription: "This is video 2",
    },
  ];

  function addSectionModal() {
    document.getElementById("add-section").showModal();
  }
  return (
    <div className="edit-courses-full-container">
      <div className="course-creator-description">
        <h1>{courseData?.courseName}</h1>
        <p>{courseData?.courseDescription}</p>
        <hr />
      </div>
      <div className="created-sections-display">
        {courseData?.sections?.map((section, index) => (
          <div key={index} className="" style={{ width: "95%" }}>
            <div className="section-container-editable">
              <div className="section-container-info">
                <h2>{section?.sectionName}</h2>
                <p>{section?.sectionDescription}</p>
              </div>
              <div className="section-option-buttons">
                <button className="edit-button">
                  <CiEdit size={30} />
                </button>
                <button className="delete-vedio">
                  <MdDelete size={30} />
                </button>
              </div>
            </div>
            <div className="section-videos">
              {fakeVedios.map((video, index) => (
                <NavLink
                  key={index}
                  className="video-container"
                  to={`/course/${BSON.ObjectId(courseData._id)}/123`}
                  state={{ course, video, user }}
                >
                  <MdOutlineSlowMotionVideo
                    size={30}
                    className="vedio-icon-display"
                  />
                  <div className="vedio-info">
                    <h3>{video?.videoName}</h3>
                    <p>{video?.videoDescription}</p>
                  </div>
                  <button className="delete-vedio">
                    <MdDelete size={30} />
                  </button>
                </NavLink>
              ))}
              <button className="add-vedio-button">Add Vedio</button>
            </div>
          </div>
        ))}
      </div>
      <button className="add-section-button" onClick={addSectionModal}>
        Add Section
      </button>
      <AddSectionModal
        courseId={`${BSON.ObjectId(courseData._id)}`}
        setCourseData={setCourseData}
      />
    </div>
  );
}

export function ErrorEditCourses() {
  return (
    <div>
      <h1>Failed to load the course</h1>
      <NavLink to="/profile" className="btn btn-primary">
        Go Back to Profile
      </NavLink>
    </div>
  );
}
