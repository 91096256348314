import { NavLink } from "react-router-dom";
import ProgressBar from "../../components/progressBar";
import React from "react";
export default function NewStepOne() {
  const [summary, setSummary] = React.useState({
    title: "",
    aiPersonality: "",
    color: "",
  });

  const handleChange = (e) => {
    setSummary({ ...summary, [e.target.name]: e.target.value });
  };
  return (
    <div className="new-summary-steps-pages">
      <div className="my-proggressBar">
        <ProgressBar progress={10} />
      </div>
      <h1 className="summary-step-title">
        step one : <span className="golden-span">costumize summary looks</span>
      </h1>
      <div className="step-one-container">
        <img
          src="https://th.bing.com/th/id/OIG4.5u7gKfvwe3ZZv__Hrp6f?pid=ImgGn"
          alt="summary image"
          className="steps-summary-images"
        />
        <div className="input-summary-step-one-fields">
          <div>
            <h1 className="meta-data-titles">summary title</h1>
            <input
              type="input"
              className="input  input-bordered w-full max-w-xs"
              placeholder="ex: summary chapter 1"
              name="title"
              onChange={handleChange}
            />
          </div>
          <div>
            <h1 className="meta-data-titles">ai personality</h1>
            <textarea
              className="textarea textarea-bordered w-full max-w-xs"
              placeholder="ex: give me only the rules of the chapter"
              name="aiPersonality"
              onChange={handleChange}
            ></textarea>
          </div>
          <div>
            <h1 className="meta-data-titles">select summary color</h1>
            <input
              type="color"
              className="color-input"
              name="color"
              onChange={handleChange}
            />
          </div>
          <NavLink
            to="/summary/new/steptwo"
            className="btn "
            state={summary}
            id="next-step"
          >
            next
          </NavLink>
        </div>
      </div>
    </div>
  );
}
