import React from "react";

export default function Footer() {
  return (
    <footer className="footer p-10 bg-base-200 text-base-content">
      <aside>
        <img
          src="https://th.bing.com/th/id/OIG3._0X_yXuQJhiPSVwzoTq7?w=1024&h=1024&rs=1&pid=ImgDetMain"
          alt="logo"
          className="fill-current"
        />
        <p>
          DR Pi
          <br />
          creating magic with ai
        </p>
      </aside>
      <nav>
        <h6 className="footer-title">Services</h6>
        <a className="link link-hover">Courses</a>
        <a className="link link-hover">Sketches</a>
        <a className="link link-hover">Exams</a>
        <a className="link link-hover">ai personalization</a>
      </nav>
      <nav>
        <h6 className="footer-title">Company</h6>
        <a className="link link-hover">About us</a>
        <a className="link link-hover">Contact</a>
      </nav>
      <nav>
        <h6 className="footer-title">Legal</h6>
        <a className="link link-hover">Terms of use</a>
        <a className="link link-hover">Privacy policy</a>
      </nav>
    </footer>
  );
}
