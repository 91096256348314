export const fakeCourses = [
  {
    _id: "5rffnfeffe",
    courseTitle: "math 4",
  },
];

export const fakeColleges = [
  {
    college: "arab academy",
    majors: [
      {
        name: "Computer Science",
        courses: ["physics I", "Math I"],
      },
      {
        name: "Engineering Junior",
        courses: [
          "physics I",
          "Math I",
          "Manufacuring",
          "Artitecture I",
          "physics II",
          "Math II",
          "Proggraming Fundumentals",
        ],
      },
      {
        name: "Computer Engineering",
        courses: ["physics I", "Math I", "Manufacuring", "Artitecture I"],
      },
    ],
  },
  {
    college: "guk",
    majors: [
      {
        name: "shorse guk",
        courses: ["physics guk", "calclus guk"],
      },
      {
        name: "shorse guk 2",
        courses: ["physics guk 2", "calclus guk 2"],
      },
    ],
  },
];
