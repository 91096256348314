import { ChatOpenAI } from "@langchain/openai";
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";

const chatModel = new ChatOpenAI({
  openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

const prompt = ChatPromptTemplate.fromMessages([
  [
    "system",
    `you are a doctor in medicine college and engineering college
  you will be taking a subject and some topics 
  you will provide a brief explanation of the subject and topics
  things you should include in the explanation are:
  1) any rules or laws that govern the subject
  2) any important concepts or theories that are relevant to the subject
  3) any important figures or events that are relevant to the subject

  for example:
  subject: physics
  topics: newton's laws of motion, theory of relativity, quantum mechanics

  output: physics is a branch of science that studies the properties of matter and energy. it is governed by newton's laws of motion, which describe how objects move in space. the theory of relativity, developed by albert einstein, explains how gravity affects the motion of objects. quantum mechanics is a theory that describes the behavior of particles at the atomic and subatomic levels.

  provite the output in markdown format
`,
  ],
  [
    "user",
    "{subject} : {topics} here is the topic and subject I want you to generate me a summary of that topics",
  ],
]);

export async function GenerateSummary(subject, topics) {
  // topics is an array change it to a string
  topics = topics.join(", ");
  const outPutParser = new StringOutputParser();
  const chain = prompt.pipe(chatModel).pipe(outPutParser);
  const output = await chain.invoke({ subject, topics });
  console.log(output);
  return output;
}

export function GenerateRoadMaps(exam, topics, time) {
  const SystemMessage = ``;
  const HumanMessage = ``;
  const prompt = ChatOpenAI.fromMessages([]);
}
