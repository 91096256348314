export const sketchBoilderPlate = [
  {
    type: "rectangle",
    version: 142,
    versionNonce: 57064058,
    isDeleted: true,
    id: "oDVXy8D6rom3H1-LLH2-f",
    fillStyle: "hachure",
    strokeWidth: 1,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 100.50390625,
    y: 93.67578125,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 186.47265625,
    height: 141.9765625,
    seed: 1968410350,
    groupIds: [],
    frameId: null,
    roundness: null,
    boundElements: [],
    updated: 1712614342345,
    link: null,
    locked: false,
  },
  {
    id: "C9zWufZA1HHR4b7zjxzGC",
    type: "diamond",
    x: -77.45355224609375,
    y: 118.99526977539062,
    width: 174.39999389648438,
    height: 104,
    angle: 0,
    strokeColor: "#1e1e1e",
    backgroundColor: "transparent",
    fillStyle: "solid",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    groupIds: [],
    frameId: null,
    roundness: {
      type: 2,
    },
    seed: 196031334,
    version: 86,
    versionNonce: 1465106982,
    isDeleted: true,
    boundElements: null,
    updated: 1712614342345,
    link: null,
    locked: false,
  },
  {
    id: "7VGOdll_D3Fw7k7uv3jVD",
    type: "rectangle",
    x: 299.3465270996094,
    y: 134.19528198242188,
    width: 171.199951171875,
    height: 67.2000732421875,
    angle: 0,
    strokeColor: "#1e1e1e",
    backgroundColor: "transparent",
    fillStyle: "solid",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    groupIds: [],
    frameId: null,
    roundness: {
      type: 3,
    },
    seed: 467293818,
    version: 32,
    versionNonce: 1247632186,
    isDeleted: true,
    boundElements: null,
    updated: 1712614342345,
    link: null,
    locked: false,
  },
  {
    type: "line",
    version: 529,
    versionNonce: 531730726,
    isDeleted: false,
    id: "8M3g1MGc-em4BaGwLiPBn",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 208.30363148638412,
    y: 181.23268281387095,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 19.417864289872504,
    height: 21.342157147427432,
    seed: 827606886,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [1.7493571432317614, 7.697171430219713],
      [8.571850001835598, 13.470050002884506],
      [16.968764289348, 15.919150003408982],
      [19.417864289872504, 9.621464287774646],
      [16.269021432055318, 0.8746785716158798],
      [10.6710785737137, -5.423007144018449],
      [3.6736500007866546, -4.898200001048943],
      [0, 0],
    ],
  },
  {
    type: "line",
    version: 489,
    versionNonce: 1391492198,
    isDeleted: false,
    id: "iStSFJVrYT0eCMXjiUsBM",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 223.07538494955315,
    y: 177.67063763887813,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 9.271592859128345,
    height: 10.6710785737137,
    seed: 56781478,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [7.522235715896584, 1.7493571432317618],
      [9.271592859128345, 8.921721430481943],
      [5.423007144018487, 10.6710785737137],
    ],
  },
  {
    type: "line",
    version: 527,
    versionNonce: 1840250790,
    isDeleted: false,
    id: "pBz4dNTeBNduOAm2y1TKF",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 197.15096805580924,
    y: 163.34719197935965,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 30.963621435202096,
    height: 13.470050002884548,
    seed: 239740390,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [13.470050002884483, 1.0496142859390867],
      [30.963621435202096, 6.8224928586038756],
      [30.263878577909345, 13.470050002884548],
    ],
  },
  {
    type: "line",
    version: 453,
    versionNonce: 1679629030,
    isDeleted: false,
    id: "GQ5uV7miXnOm4GNxwU6If",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 213.77190738513787,
    y: 165.8606436050518,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 8.221978573189263,
    height: 9.62146428777465,
    seed: 857241894,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [8.221978573189263, 2.274164286201268],
      [6.822492858603837, 9.62146428777465],
    ],
  },
  {
    type: "line",
    version: 1393,
    versionNonce: 482659878,
    isDeleted: false,
    id: "FqIj1XeeezukeWY-wKNnC",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 176.71873533480198,
    y: 167.4487605337465,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 51.25616429669047,
    height: 69.09960715765446,
    seed: 691692646,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [0, -9.096657144805143],
      [2.6240357148476416, -18.893057146902965],
      [8.396914287512395, -23.44138571930554],
      [16.618892860701663, -25.36567857686047],
      [20.642414290134724, -36.91143572219008],
      [26.94010000576901, -35.51195000760467],
      [24.49100000524458, -25.015807148214126],
      [26.24035714847635, -32.53804286411071],
      [26.765164291445892, -33.06285000708016],
      [31.13855714952529, -31.838300006817928],
      [28.16465000603131, -20.817350004457854],
      [31.83830000681803, -15.394342860439426],
      [32.538042864110714, -12.245500002622261],
      [30.613750006555833, -9.796400002097783],
      [26.940100005769096, -7.697171430219694],
      [20.467478575811597, -6.64755714428064],
      [18.89305714690304, -5.5979428583415825],
      [18.89305714690304, 0.8746785716159167],
      [13.295114288561422, 5.772878572664862],
      [1.0496142859390858, 17.843442860963993],
      [-8.221978573189189, 28.514521434677697],
      [-12.945242859914933, 32.18817143546439],
      [-17.143700003671206, 30.438814292232628],
      [-18.718121432579757, 24.141128576598327],
      [-17.493571432317538, 11.370821431006485],
      [-14.344728574500357, 3.148842857817222],
      [-7.3473000015733145, -0.1749357143231336],
      [-0.5248071429694647, 1.2245500002622904],
      [8.047042858866131, 8.571850001835676],
    ],
  },
  {
    type: "line",
    version: 805,
    versionNonce: 613685606,
    isDeleted: false,
    id: "FaaVXRuEmiDC7xUIDWz08",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 168.17726854207035,
    y: 165.91194543290612,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 25.365678576860457,
    height: 40.76002143729994,
    seed: 847046566,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-1.3994857145853503, -12.245500002622343],
      [0.1749357143232052, -25.36567857686051],
      [4.198457143756269, -40.76002143729995],
      [13.295114288561415, -38.835728579745016],
      [13.120178574238206, -30.78868572087896],
      [23.091514290659223, -32.18817143546436],
      [23.7912571479519, -24.491000005244636],
      [23.966192862275104, -37.43624286515964],
      [14.69460000314676, -39.53547143703775],
      [14.869535717469976, -39.36053572271457],
    ],
  },
  {
    type: "line",
    version: 760,
    versionNonce: 1658322086,
    isDeleted: false,
    id: "NIPrUugH9gYTDZfn5DLDw",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 211.454657305262,
    y: 191.19064599668604,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 7.34730000157338,
    height: 48.982000010489216,
    seed: 1326252774,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [0.874678571615881, 10.496142859390568],
      [0, 19.24292857554934],
      [-1.749357143231761, 28.164650006031312],
      [-1.9242928575548923, 35.337014293281484],
      [-0.8746785716158807, 41.809635723239],
      [-2.7989714291707726, 48.982000010489216],
      [-1.399485714585349, 41.98457143756216],
      [-1.399485714585349, 36.0367571505742],
      [0.5248071429695431, 41.809635723239],
      [4.548328572402608, 44.95847858105617],
      [4.548328572402608, 44.95847858105617],
    ],
  },
  {
    type: "line",
    version: 546,
    versionNonce: 1295874022,
    isDeleted: false,
    id: "vU1RX-2OrAaJk6E5wnyTp",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 213.18370077271857,
    y: 204.8236457294238,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 14.86953571746998,
    height: 12.07056428829912,
    seed: 1903670822,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [6.822492858603843, 3.1488428578171472],
      [14.86953571746998, 12.07056428829912],
    ],
  },
  {
    type: "line",
    version: 534,
    versionNonce: 466717478,
    isDeleted: false,
    id: "zyfzfcGhzQ442NRzLkbrE",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 221.4965160844655,
    y: 211.94999997077593,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 1.3994857145853494,
    height: 10.846014288036903,
    seed: 850002278,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-0.5248071429694693, 3.67365000078669],
      [-0.5248071429694693, 7.697171430219755],
      [0.8746785716158801, 10.846014288036903],
    ],
  },
  {
    type: "line",
    version: 529,
    versionNonce: 2097231462,
    isDeleted: false,
    id: "unQbHHb2vhfFaVoZDZzRq",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 209.26260766292398,
    y: 196.4919284082473,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 16.61889286070166,
    height: 19.592800004195666,
    seed: 99673254,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346318,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-5.597942858341614, 2.274164286201267],
      [-8.221978573189263, 8.921721430481936],
      [-12.59537143126859, 14.694600003146753],
      [-16.618892860701663, 19.592800004195666],
    ],
  },
  {
    type: "line",
    version: 452,
    versionNonce: 1234794918,
    isDeleted: false,
    id: "KgwvLLWlFww7odBXvunAE",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 201.59204526647636,
    y: 206.12867335910005,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 7.522235715896589,
    height: 16.96876428934803,
    seed: 1828282342,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346319,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-2.0992285718781005, 8.571850001835596],
      [-7.52223571589659, 16.96876428934803],
    ],
  },
  {
    type: "line",
    version: 787,
    versionNonce: 952587494,
    isDeleted: false,
    id: "oETolLdE0bmXAEXXpgKaW",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 224.73972457699324,
    y: 198.7294868195197,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 66.65050715712994,
    height: 60.177885727172395,
    seed: 735081254,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346319,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [4.373392858079416, 7.69717143021972],
      [5.772878572664837, 22.916578576335986],
      [-0.5248071429694505, 46.18302858131835],
      [-8.746785716158712, 57.20397858367844],
      [-16.094085717732113, 58.42852858394063],
      [-35.511950007604625, 50.90629286804408],
      [-54.5799428688308, 34.46233572166559],
      [-60.8776285844651, 20.642414290134706],
      [-60.52775715581875, 9.096657144805105],
      [-58.07865715529432, -1.7493571432317605],
    ],
  },
  {
    type: "line",
    version: 497,
    versionNonce: 337102886,
    isDeleted: false,
    id: "CkH2Jb8TCzjE3i7QWWocg",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 179.11801749579809,
    y: 190.8379520777089,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 9.44652857345148,
    height: 28.33958572035444,
    seed: 2095259238,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346319,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-6.122750001311166, 9.096657144805105],
      [-9.44652857345148, 19.76773571851884],
      [-8.221978573189261, 28.33958572035444],
    ],
  },
  {
    type: "line",
    version: 476,
    versionNonce: 1803015014,
    isDeleted: false,
    id: "7oaAbF106flPU0bMdN9Wp",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 187.42598863438718,
    y: 148.0289606982733,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 5.248071429695283,
    height: 14.69460000314676,
    seed: 1157521830,
    groupIds: ["oK4LNs15M6s_M8F4F4v_-"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614346319,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-4.023521429433065, 5.9478142869879544],
      [-5.248071429695283, 14.69460000314676],
    ],
  },
  {
    id: "g_cXHAhnScTfIzkZHx47a",
    type: "arrow",
    x: 177.74649047851562,
    y: 123.7952880859375,
    width: 147.2000732421875,
    height: 136.7157196950962,
    angle: 0,
    strokeColor: "#1e1e1e",
    backgroundColor: "transparent",
    fillStyle: "solid",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    groupIds: [],
    frameId: null,
    roundness: {
      type: 2,
    },
    seed: 1802514106,
    version: 639,
    versionNonce: 1880256742,
    isDeleted: false,
    boundElements: null,
    updated: 1712614511139,
    link: null,
    locked: false,
    points: [
      [0, 0],
      [36.000030517578125, -84],
      [147.2000732421875, -136.7157196950962],
    ],
    lastCommittedPoint: null,
    startBinding: null,
    endBinding: {
      elementId: "_SHD-3E9qAVPqXV1pnABX",
      focus: 0.3330708620902029,
      gap: 2.400054931640625,
    },
    startArrowhead: null,
    endArrowhead: "arrow",
  },
  {
    id: "dlzHrIS085zU8_u-gpisF",
    type: "text",
    x: -54.453521728515625,
    y: 39.7952880859375,
    width: 10,
    height: 25,
    angle: 0,
    strokeColor: "#1e1e1e",
    backgroundColor: "transparent",
    fillStyle: "solid",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    groupIds: [],
    frameId: null,
    roundness: null,
    seed: 310037050,
    version: 3,
    versionNonce: 2009103482,
    isDeleted: true,
    boundElements: null,
    updated: 1712614390021,
    link: null,
    locked: false,
    text: "",
    fontSize: 20,
    fontFamily: 1,
    textAlign: "left",
    verticalAlign: "top",
    baseline: 18,
    containerId: null,
    originalText: "",
    lineHeight: 1.25,
  },
  {
    id: "_SHD-3E9qAVPqXV1pnABX",
    type: "text",
    x: 327.34661865234375,
    y: -53.00462341308594,
    width: 88.159912109375,
    height: 75,
    angle: 0,
    strokeColor: "#1e1e1e",
    backgroundColor: "transparent",
    fillStyle: "solid",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    groupIds: [],
    frameId: null,
    roundness: null,
    seed: 135466150,
    version: 329,
    versionNonce: 1930958246,
    isDeleted: false,
    boundElements: [
      {
        id: "g_cXHAhnScTfIzkZHx47a",
        type: "arrow",
      },
    ],
    updated: 1712614511134,
    link: null,
    locked: false,
    text: "sketch \nwith your\nheart",
    fontSize: 20,
    fontFamily: 1,
    textAlign: "left",
    verticalAlign: "top",
    baseline: 68,
    containerId: null,
    originalText: "sketch \nwith your\nheart",
    lineHeight: 1.25,
  },
  {
    type: "line",
    version: 947,
    versionNonce: 1153640166,
    isDeleted: false,
    id: "_l8NI_W4Cs4_lc6qex1RG",
    fillStyle: "hachure",
    strokeWidth: 1,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 570.5466059071625,
    y: 233.0107010085411,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 24.435896791533413,
    height: 91.8512432589554,
    seed: 400701606,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [2.5995634884610217, -11.438079349228381],
      [0.5199126976921971, -21.316420605380188],
      [-10.74486241897216, -33.794325349993045],
      [-16.63720632615042, -52.16457400178409],
      [-15.424076698201974, -67.9352591651142],
      [-5.372431209486059, -83.53264009588021],
      [7.7986904653829905, -91.85124325895539],
    ],
  },
  {
    type: "line",
    version: 792,
    versionNonce: 348740134,
    isDeleted: false,
    id: "xY22rTBeZ6VgsvniEQ-qo",
    fillStyle: "hachure",
    strokeWidth: 1,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 582.0299952256719,
    y: 142.56186023157318,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 21.14311637281616,
    height: 3.812693116409468,
    seed: 1570780134,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [9.531732791023662, -2.772867721025076],
      [21.14311637281616, -3.812693116409469],
    ],
  },
  {
    type: "line",
    version: 943,
    versionNonce: 1143416166,
    isDeleted: false,
    id: "Xcet3you52DyA-JNPfv1l",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 604.3220737410613,
    y: 139.87489393293924,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 38.126931164094756,
    height: 57.36370097870613,
    seed: 142395174,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [8.66521162820333, 1.559738093076628],
      [19.75668251230362, 8.83851586076742],
      [26.168939117174084, 18.890161349483286],
      [29.288415303327263, 32.75449995460867],
      [29.288415303327263, 39.513365024607246],
      [35.180759210505585, 49.39170628075908],
      [38.126931164094756, 57.36370097870613],
    ],
  },
  {
    type: "line",
    version: 882,
    versionNonce: 875137190,
    isDeleted: false,
    id: "aDpqktBCKKV3CeY8EVO2G",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 642.1992539513108,
    y: 197.50625546992444,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 24.95580948922567,
    height: 42.63284121076044,
    seed: 686092902,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-1.039825395384396, 3.6393888838453954],
      [-8.318603163075226, 4.6792142792297895],
      [-9.011820093331506, 12.30460051204875],
      [-8.145298930511133, 18.196944419227005],
      [-9.185124325895528, 23.39607139614901],
      [-9.531732791023709, 29.981632233583547],
      [-14.210947070253477, 32.75449995460863],
      [-22.702854465892784, 36.04728037332592],
      [-24.955809489225665, 42.63284121076044],
    ],
  },
  {
    type: "line",
    version: 785,
    versionNonce: 343770086,
    isDeleted: false,
    id: "oR-F7KsOEXMMilGZ_Pn36",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 569.9525065437856,
    y: 171.80354250438393,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 7.400101920766438,
    height: 19.063465582047357,
    seed: 967161254,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-4.2286296690094085, -5.819373703993414],
      [-2.907182897443962, -13.44475993681236],
      [3.171472251757029, -19.063465582047357],
    ],
  },
  {
    type: "line",
    version: 657,
    versionNonce: 389027622,
    isDeleted: false,
    id: "or1wf1fRxYi0N3wT7vTiE",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 592.8582451350201,
    y: 158.07698877202358,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 7.278777767690829,
    height: 4.679214279229847,
    seed: 1407979750,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [1.5597380930766278, -3.1194761861532188],
      [7.278777767690829, -4.679214279229847],
    ],
  },
  {
    type: "line",
    version: 661,
    versionNonce: 615400038,
    isDeleted: false,
    id: "xdtgE7sYIqgTbQJXu4U-0",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 589.6325868653378,
    y: 158.59848156572377,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 15.250772465637912,
    height: 5.545735442050146,
    seed: 1665467430,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [3.1194761861532174, -3.466084651281324],
      [8.318603163075187, -5.545735442050146],
      [15.250772465637912, -3.466084651281324],
    ],
  },
  {
    type: "line",
    version: 690,
    versionNonce: 264570278,
    isDeleted: false,
    id: "bVclu_hpwMoDHvXZCpaUK",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 599.9205395736101,
    y: 157.0564557999786,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 9.87834125615181,
    height: 3.466084651281325,
    seed: 1503498086,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [5.199126976922117, -2.426259255896968],
      [9.87834125615181, 1.0398253953843566],
      [9.705037023587794, 1.0398253953843566],
    ],
  },
  {
    type: "line",
    version: 696,
    versionNonce: 1235312870,
    isDeleted: false,
    id: "B8qy6RYTQpuW6owwHLstU",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 610.4670220648047,
    y: 153.18545099894743,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 12.997817442305074,
    height: 24.089288326405313,
    seed: 1665315494,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [6.93216930256265, 2.0796507907688233],
      [11.091470884100302, 8.838515860767417],
      [10.918166651536207, 16.637206326150437],
      [6.412256604870453, 22.702854465892784],
      [-1.9063465582047723, 24.089288326405313],
    ],
  },
  {
    type: "line",
    version: 747,
    versionNonce: 365974566,
    isDeleted: false,
    id: "j0O57oD9qIYh2YvaG3TVr",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 604.970457576851,
    y: 173.5130605458811,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 40.206581954863545,
    height: 14.73085976794568,
    seed: 450981350,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-1.2131296279484793, 7.452082000254864],
      [-10.051645488715902, 12.304600512048754],
      [-18.02364018666295, 12.651208977176871],
      [-26.16893911717411, 14.73085976794568],
      [-33.27441265230087, 13.864338605125353],
      [-38.820148094351026, 9.705037023587744],
      [-40.20658195486355, 2.42625925589693],
    ],
  },
  {
    type: "line",
    version: 644,
    versionNonce: 1044307814,
    isDeleted: false,
    id: "nRBKgLlQ1bj73IhRnYkr5",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 587.8247827045507,
    y: 173.48359824512124,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 4.679214279229773,
    height: 7.625386232818956,
    seed: 1032241446,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [0.8665211628203036, 3.2927804187172907],
      [-3.812693116409469, 7.625386232818956],
    ],
  },
  {
    type: "line",
    version: 733,
    versionNonce: 1194984102,
    isDeleted: false,
    id: "836NqP2cZjiG-VdiSUqxk",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 609.3794175684968,
    y: 159.77794935643976,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 6.238952372306434,
    height: 3.9859973489744713,
    seed: 1587522662,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450902,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-1.1492807001616532, 3.7515269166818266],
      [-6.238952372306434, 3.9859973489744713],
    ],
  },
  {
    type: "line",
    version: 658,
    versionNonce: 594460134,
    isDeleted: false,
    id: "NM6dKl_SlIL_BEMfgAIKp",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 584.065200341143,
    y: 156.90856111054597,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 7.278777767690794,
    height: 6.238952372306436,
    seed: 469315494,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450903,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [2.252955023332841, 4.159301581537647],
      [7.278777767690794, 6.238952372306436],
    ],
  },
  {
    type: "line",
    version: 676,
    versionNonce: 1743393062,
    isDeleted: false,
    id: "CJYXi01VbIxOzo8GMRZH7",
    fillStyle: "hachure",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 575.6228279559332,
    y: 160.41389072009054,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 2.4262592558969684,
    height: 8.66521162820333,
    seed: 1371556582,
    groupIds: ["gmeMouAWKoajAMzU-Ur3v"],
    frameId: null,
    roundness: {
      type: 2,
    },
    boundElements: [],
    updated: 1712614450903,
    link: null,
    locked: false,
    startBinding: null,
    endBinding: null,
    lastCommittedPoint: null,
    startArrowhead: null,
    endArrowhead: null,
    points: [
      [0, 0],
      [-1.5597380930766276, 3.1194761861531815],
      [0.8665211628203409, 8.66521162820333],
    ],
  },
  {
    id: "6KoeUHk2IjQPDdQ1bzLJA",
    type: "arrow",
    x: 588.1465148925781,
    y: 249.39532470703125,
    width: 120.00009155273438,
    height: 167.20001220703125,
    angle: 0,
    strokeColor: "#1e1e1e",
    backgroundColor: "transparent",
    fillStyle: "solid",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    groupIds: [],
    frameId: null,
    roundness: {
      type: 2,
    },
    seed: 1640529830,
    version: 187,
    versionNonce: 1139439930,
    isDeleted: false,
    boundElements: null,
    updated: 1712614478429,
    link: null,
    locked: false,
    points: [
      [0, 0],
      [-27.20001220703125, 131.199951171875],
      [-120.00009155273438, 167.20001220703125],
    ],
    lastCommittedPoint: null,
    startBinding: null,
    endBinding: null,
    startArrowhead: null,
    endArrowhead: "arrow",
  },
  {
    id: "zCKQTXpZqAtObhVCkw_rk",
    type: "text",
    x: 337.146484375,
    y: 380.7952880859375,
    width: 102.37991333007812,
    height: 50,
    angle: 0,
    strokeColor: "#1e1e1e",
    backgroundColor: "transparent",
    fillStyle: "solid",
    strokeWidth: 2,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    groupIds: [],
    frameId: null,
    roundness: null,
    seed: 1534390970,
    version: 65,
    versionNonce: 457980986,
    isDeleted: false,
    boundElements: null,
    updated: 1712614491729,
    link: null,
    locked: false,
    text: "think with \nyour mind",
    fontSize: 20,
    fontFamily: 1,
    textAlign: "left",
    verticalAlign: "top",
    baseline: 43,
    containerId: null,
    originalText: "think with \nyour mind",
    lineHeight: 1.25,
  },
];
