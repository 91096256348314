import React from "react";
import { Outlet } from "react-router";
import signUpImage from "../../../assets/login-page.png";
export default function SignUpWrapper() {
  return (
    <div className="sign-up-page">
      <img src={signUpImage} alt="login" className="sign-up-image" />
      <span
        className="infinity-loop loading-in-middle loading loading-infinity loading-lg"
        id="infinity-loop"
      ></span>
      <Outlet />
    </div>
  );
}
