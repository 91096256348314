import ".././App.css";
import { defer, Await, useOutletContext } from "react-router-dom";
import { Suspense } from "react";
import EnrolledCoursesDisplay from "../components/enrolledCourseDisplay";
import TodayTodoTaks from "../components/TodayTodoTaks";
import Footer from "../components/fotter";
import { getUserCreatedCourses } from "../utils/courses-backend";
export default function Home() {
  const contextData = useOutletContext();
  const userName = JSON.parse(contextData?.myUser)?.data?.username || "";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100vw",
        overflowX: "hidden",
      }}
    >
      <h1
        style={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "2rem",
          marginRight: "0px",
          marginLeft: "0px",
        }}
      >
        Welcome Back{" "}
        <span
          style={{
            color: "goldenrod",
            textTransform: "capitalize",
          }}
        >
          {userName ? userName : "User"}
        </span>
      </h1>
      <div className="home">
        {/* <div className="left-container">
        <h1>
        start studing with <span className="golden-span">ai</span> easily it
          just feels like <span className="golden-span">magic</span>
          </h1>
      </div>
      <div className="image-div">
      <img
          src="https://th.bing.com/th/id/OIG4.LjzFWPG1P7HTsDQ3Wdp2?pid=ImgGn"
          alt="girl flying in space"
          className="image-home"
        />
      </div> */}
        <div className="enrolled-courses-and-analytics-display">
          <div className="enrolled-courses-display card card-bordered shadow-xl glass">
            <p>continue learning</p>
            <EnrolledCoursesDisplay />
          </div>
          <div className="analytics-home-page enrolled-courses-display card card-bordered shadow-xl glass">
            <p>DR PI News</p>
            <h1>no news for now</h1>
          </div>
        </div>
        <div className="today-todo-tasks card card-bordered shadow-xl glass">
          <TodayTodoTaks />
        </div>
      </div>
      <div className="home-page-footer">
        <Footer />
      </div>
    </div>
  );
}

export async function loader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return { user: false };
  }
}
