import React from "react";
import ProgressBar from "../../../components/progressBar";
import toast, { Toaster } from "react-hot-toast";
import { noEmailRepeat } from "../../../utils/auth-backend";
import { useNavigate } from "react-router";
export default function SignUpStepOne() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState({
    username: "",
    email: "",
    password: "",
  });

  const [submitting, setSubmitting] = React.useState(false);
  function handleChange(event) {
    setEmail((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  const myRef = React.useRef(null);

  React.useEffect(() => {
    myRef.current.focus();
  }, []);
  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  async function handleNagivation(event) {
    setSubmitting(true);
    event.preventDefault();
    // validate all the inputs
    if (!email.email || !email.password || !email.username) {
      toast.error("email or password cannot be empty");
      setSubmitting(false);
      return;
    }
    if (!validateEmail(email.email)) {
      toast.error("email is invalid");
      setSubmitting(false);
      return;
    }

    try {
      // redirect to the next page
      const response = await noEmailRepeat(email.email);
      if (response.status === "error") {
        toast.error(response.error);
        return;
      }
      toast.success("email is valid");
      navigate("stepTwo", { state: email });
    } catch (error) {
      toast.error("error authenticating user");
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <div
      className="sign-up-step-one"
      id={submitting ? "container-submiting" : ""}
    >
      <ProgressBar progress={40} />
      <h1>create an account</h1>
      <p>Enter your email and password to sign up</p>
      <form>
        <div className="form-group">
          <label htmlFor="email">Email : </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            value={email.email}
            className="sign-up-inputs input input-bordered "
            ref={myRef}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password: </label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={handleChange}
            value={email.password}
            className="sign-up-inputs input input-bordered "
          />
        </div>
        <div className="form-group">
          <label htmlFor="username">Username: </label>
          <input
            type="text"
            name="username"
            id="username"
            onChange={handleChange}
            value={email.username}
            className="sign-up-inputs input input-bordered "
          />
        </div>
        <button
          className="btn btn-primary"
          onClick={handleNagivation}
          disabled={submitting}
        >
          Next
        </button>
      </form>
      <Toaster />
    </div>
  );
}
