import React from "react";
import ProgressBar from "../../components/progressBar";
import { useLocation, useNavigate } from "react-router-dom";
import { fakeSubjects } from "./fakeData";
import { GenerateSummary } from "../../utils/ai-backend";

export default function NewStepTwo() {
  const { state } = useLocation();
  const [submitting, setSubmitting] = React.useState(false);
  const [summary, setSummary] = React.useState({
    ...state,
    subject: "",
    chapters: [],
  });
  const navigate = useNavigate();
  function handleAddingChapters(event) {
    const { value } = event.target;
    // check if the value is checked or not
    if (event.target.checked) {
      // add the chapter to the chapters array
      setSummary({ ...summary, chapters: [...summary.chapters, value] });
    } else {
      // remove the chapter from the chapters array
      setSummary({
        ...summary,
        chapters: summary.chapters.filter((chapter) => chapter !== value),
      });
    }
  }
  // Function to handle changes in subject selection
  const handleSubjectChange = (event) => {
    // Update the state with the selected subject
    setSummary({ ...summary, subject: event.target.value, chapters: [] });
  };

  async function handleSubmit() {
    // simulate a submit for 2 seconds
    setSubmitting(true);
    try {
      const data = await GenerateSummary(summary.subject, summary.chapters);
      console.log(data, "the data is here ");
      // set the summary data
      navigate("/summary/new/aiPreview", { state: { ...summary, data } });
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
    // redirect to the next page
  }
  return (
    <div
      className="new-summary-steps-pages"
      id={submitting ? "container-submiting" : ""}
    >
      <div className="my-proggressBar">
        <ProgressBar progress={50} color={"warning"} />
      </div>
      <h1 className="summary-step-title">
        step two : <span className="golden-span">subject & chapters</span>
      </h1>
      <div className="step-two-container">
        <div className="radio-button-container">
          <h1 className="step-two-meta-data-titles">subject</h1>
          {Object.keys(fakeSubjects).map((subject, index) => (
            <label key={index} className="radio-label">
              <input
                type="radio"
                name="subject"
                value={subject}
                onChange={handleSubjectChange}
                checked={summary.subject === subject}
                className="radio-input"
              />
              <span className="radio-text">{subject}</span>
            </label>
          ))}
        </div>
        <span className="loading-in-middle loading loading-infinity loading-lg"></span>
        <div className="chapters-checkboxes radio-button-container">
          <h1 className="step-two-meta-data-titles">chapters</h1>
          {summary.subject &&
            fakeSubjects[summary.subject].chapterNames.map((subject, index) => (
              <label key={subject} className="radio-label">
                <input
                  type="checkbox"
                  name="chapters"
                  value={subject}
                  onChange={handleAddingChapters}
                  className="checkbox checkbox-warning"
                />
                <span className="check-box-text">{subject}</span>
              </label>
            ))}
          {summary.chapters.length > 0 && (
            <button
              className={`btn ${submitting && "btn-disabled"}`}
              id="next-step"
              onClick={handleSubmit}
              disabled={submitting}
            >
              {submitting ? "Generating" : "Generate"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
