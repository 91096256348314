import React, { useState } from "react";
import "./BasicCalculator.css"; // Import CSS file for styling

function BasicCalculator({ closeCalculator }) {
  const [expression, setExpression] = useState("");
  const [result, setResult] = useState("");

  // Function to handle button clicks
  const handleButtonClick = (value) => {
    if (value === "=") {
      try {
        const evaluatedResult = eval(expression);
        setResult(evaluatedResult);
      } catch (error) {
        setResult("Error");
      }
    } else if (value === "C") {
      setExpression("");
      setResult("");
    } else {
      setExpression((prevExpression) => prevExpression + value);
    }
  };

  return (
    <dialog id="calculator" className="modal">
      <div className="calculator-model modal-box">
        <div className="calculator">
          {/* <h1>Basic Calculator</h1> */}
          <div className="display">
            <input
              type="text"
              value={expression}
              readOnly
              className="expression"
            />
            <div className="result">{result}</div>
          </div>
          <div className="buttons">
            {[
              "7",
              "8",
              "9",
              "+",
              "4",
              "5",
              "6",
              "-",
              "1",
              "2",
              "3",
              "*",
              "C",
              "0",
              "=",
              "/",
            ].map((button) => (
              <button
                key={button}
                onClick={() => handleButtonClick(button)}
                className={`button ${button === "=" ? "equal" : ""}`}
              >
                {button}
              </button>
            ))}
          </div>
        </div>
        <div className=" close-calculator modal-action">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="close-calculator btn">X</button>
          </form>
        </div>
      </div>
    </dialog>
  );
}

export default BasicCalculator;
