import { redirect } from "react-router";
import { connectingToDatabase } from "./sketch-Backend";

export async function createNewCourse(data) {
  const user = await connectingToDatabase();
  try {
    const courseId = await user.functions.creatingNewCourse(data);
    if (courseId) {
      return {
        status: "success",
        courseId: courseId,
        message: "successfully created course",
      };
    } else {
      return {
        status: "error",
        error: "error creating course",
      };
    }
  } catch (error) {
    return {
      status: "error",
      error: "error creating course",
    };
  }
}

export async function getUserCourses(email) {
  const user = await connectingToDatabase();
  try {
    const courses = await user.functions.getUserCreatedCourses(email);
    if (courses) {
      console.log(courses, "user courses");
      return {
        status: "success",
        courses: courses,
      };
    } else {
      return {
        status: "error",
        error: "error getting user courses",
      };
    }
  } catch (error) {
    return {
      status: "error",
      error: "error getting user courses",
    };
  }
}

export async function GetUserEnrolledCourses(id) {
  const user = await connectingToDatabase();
  try {
    const enrolledCourses = await user.functions.getEnrolledUserCourses(id);
    return { status: "success", enrolledCourses: enrolledCourses };
  } catch (err) {
    return {
      status: "error",
      message: "error getting the enrolled courses",
    };
  }
}

export async function getFilteredCourses(college, major, course) {
  const user = await connectingToDatabase();
  try {
    const filteredCourses = await user.functions.filterCourses(
      college,
      major,
      course
    );
    return { status: "success", filteredCourses: filteredCourses };
  } catch (error) {
    return { status: "error", error: "error getting filtered courses" };
  }
}

export async function getUserCreatedCourses(email) {
  try {
    const user = await connectingToDatabase();
    const userCourses = await user.functions.getUserCreatedCourses(email);
  } catch (err) {
    return {
      status: "error",
      error: "error getting user created courses",
    };
  }
}

export async function addingSectionToCourse(sectionData, courseId) {
  try {
    const user = await connectingToDatabase();
    const response = await user.functions.addNewSection(sectionData, courseId);
    console.log(response, "response from adding section to course");
    if (response.status === 200) {
      return {
        status: "success",
        message: "successfully added section to course",
      };
    } else {
      return {
        status: "error",
        error: "error adding section to course",
      };
    }
  } catch (error) {
    return {
      status: "error",
      error: "error adding section to course",
    };
  }
}
