import React from "react";
import {
  NavLink,
  useNavigate,
  redirect,
  useLoaderData,
} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { createNewCourse } from "../../utils/courses-backend";
import { IoIosArrowBack } from "react-icons/io";

export default function CreateNewCoursePage() {
  const user = useLoaderData();
  const [course, setCourse] = React.useState({
    courseName: "",
    courseDescription: "",
    creator: user.email,
    college: "",
    major: "",
    theme: "",
  });
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    setCourse((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  async function handleCreatingCourse() {
    // check that all the inputs are filled
    if (
      course.courseName === "" ||
      course.courseDescription === "" ||
      course.creator === "" ||
      course.college === "" ||
      course.major === ""
    ) {
      toast.error("Please fill all the inputs");
      return;
    }
    setSubmitting(true);
    try {
      const response = await createNewCourse(course);
      if (response.status === "success") {
        toast.success("added new course successfully");

        navigate("/courses");
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error("Error creating course");
    } finally {
      setSubmitting(false);
    }
  }

  const myThemes = ["sparky", "bubbly", "deserty", "wavy", "linear"];

  function handleThemeChange(theme) {
    setCourse((prev) => {
      return {
        ...prev,
        theme,
      };
    });
  }

  return (
    <div
      className={`new-course-container ${course.theme}`}
      id={submitting ? "container-submiting" : ""}
    >
      <h1>Create New Course</h1>
      <NavLink to="/courses" className="back-to-courses">
        <IoIosArrowBack />
      </NavLink>
      <div className="new-course-form">
        <div className="course-input-with-label">
          <div className="course-input-with-label">
            <label htmlFor="creator">Creator : </label>
            <input
              type="text"
              name="creator"
              value={user.email}
              placeholder="ex. Sara Elmadany"
              className="input input-bordered "
              disabled
            />
          </div>
        </div>

        <div className="course-input-with-label">
          <label htmlFor="college">College : </label>
          <select
            name="college"
            value={course.college}
            onChange={handleChange}
            placeholder="ex. arab academy"
            className="select select-bordered"
          >
            <option
              value=""
              disabled
              style={{
                display: "none",
              }}
            >
              Select College
            </option>
            <option value="arab academy">Arab Academy</option>
            <option value="auc">AUC</option>
            <option value="guc">GUC</option>
            <option value="anu">ANU</option>
          </select>
        </div>
        <div className="course-input-with-label">
          <label htmlFor="major">Major : </label>
          <select
            name="major"
            value={course.major}
            onChange={handleChange}
            className="select select-bordered"
          >
            <option
              value=""
              disabled
              style={{
                display: "none",
              }}
            >
              Select Major
            </option>
            <option value="Computer Science">Computer Science</option>
            <option value="Business Administration">
              Business Administration
            </option>
            <option value="Engineering">Engineering</option>
            <option value="Arts">Arts</option>
            <option value="Science">Science</option>
          </select>
        </div>
        <div>
          <label htmlFor="courseName">Course Name : </label>
          <input
            type="text"
            name="courseName"
            value={course.courseName}
            onChange={handleChange}
            placeholder="ex. Math I"
            className="input input-bordered mb-4"
          />
        </div>
        <div className="course-textarea-with-label">
          <label htmlFor="courseDescription">Course Description </label>
          <textarea
            type="text"
            name="courseDescription"
            value={course.courseDescription}
            onChange={handleChange}
            placeholder="ex. This course is about the basics of math I."
            className="textarea textarea-bordered text-area-input-course"
          />
        </div>
        <h1 className="themes-title">Themes</h1>
        <div className="themes-display">
          {myThemes.map((theme, index) => (
            <button
              key={index}
              className={`theme-display-container ${theme}`}
              onClick={() => handleThemeChange(theme)}
            >
              {theme}
            </button>
          ))}
        </div>
        <button
          className="btn create-course-btn"
          onClick={handleCreatingCourse}
        >
          Create Course
        </button>
      </div>
      <Toaster />
    </div>
  );
}

export function loader() {
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(user);
  if (!user.data) {
    return redirect("/login?message=Please login first to create a new course");
  }

  return user.data;
}
