import React, { useState } from "react";
import { NavLink, useLoaderData, useSearchParams } from "react-router-dom";
import "./courses.css";
import { fakeColleges, fakeCourses } from "./fakeCourses";
import { getFilteredCourses } from "../../utils/courses-backend";
import toast from "react-hot-toast";
import DisplaySingleCourse from "../../components/DisplaySingleCourse";
import Footer from "../../components/fotter";
import { BSON } from "realm-web";
export default function AllCourses() {
  const [searchBar, setSearchBar] = useSearchParams();
  const [submitting, setSubmitting] = React.useState(false);
  React.useEffect(() => {
    if (searchBar) {
      // fetch data from the data base;
      // console.log(searchBar, "get with that some data from the db");
    }

    return () => {};
  }, [searchBar]);
  const [filter, setFilter] = React.useState({
    college: "",
    major: {
      allMajors: [],
      selectedMajor: "",
    },
    courses: {
      allCouerses: [],
      selectedCourse: "",
    },
  });

  const [renderedCourses, setCourses] = React.useState([]);
  console.log(renderedCourses, "rendered courses  ");
  function changeCollege(e) {
    setFilter((prev) => {
      return {
        ...prev,
        college: e.target.value,
        major: {
          ...prev.major,
          allMajors: fakeColleges
            .find((college) => college.college === e.target.value)
            .majors.map((major) => major.name),
        },
        courses: {
          allCouerses: [],
          selectedCourse: "",
        },
      };
    });
  }

  function changeMajor(e) {
    const filteredCourses = fakeColleges
      .find((college) => college.college === filter.college)
      .majors.find((major) => major.name === e.target.value).courses;

    setFilter((prev) => {
      return {
        ...prev,
        major: {
          ...prev.major,
          selectedMajor: e.target.value,
        },
        courses: {
          ...prev.courses,
          allCouerses: filteredCourses,
        },
      };
    });
  }

  function handleCoursesChange(e) {
    setFilter((prev) => {
      return {
        ...prev,
        courses: {
          ...prev.courses,
          selectedCourse: e.target.value,
        },
      };
    });
  }

  async function handleSubmit() {
    const finalFilter = {
      college: filter.college,
      major: filter.major.selectedMajor,
      course: filter.courses.selectedCourse,
    };

    setSearchBar(finalFilter);
    setSubmitting(true);
    try {
      const data = await getFilteredCourses(
        filter.college,
        filter.major.selectedMajor,
        filter.courses.selectedCourse
      );
      if (data.status === "success") {
        setCourses(data.filteredCourses);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error getting courses");
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div
      className="full-courses-container"
      id={submitting ? "container-submiting" : ""}
    >
      <div className="filter-courses-container crad ">
        {/* <h1>
          Filter&Find your
          <span>Courses</span>
        </h1> */}
        <div className="filter-courses">
          <select
            name="college"
            id="category"
            onChange={changeCollege}
            value={filter.college}
            className="select select-info select-bordered w-full max-w-xs"
          >
            <option
              value=""
              // don't forget to add the disabled option
              disabled
              style={{
                display: "none",
              }}
            >
              Select College
            </option>
            {fakeColleges?.map((college, index) => (
              <option value={college?.college} key={index}>
                {college.college}
              </option>
            ))}
          </select>
          <select
            name="major"
            id="category"
            className={"select select-info select-bordered w-full max-w-xs"}
            onChange={changeMajor}
            disabled={!filter.college}
            value={filter.major.selectedMajor}
          >
            <option
              value=""
              disabled
              style={{
                display: "none",
              }}
            >
              Select Major
            </option>
            {filter.major?.allMajors?.map((major, index) => {
              return (
                <option value={major} key={index}>
                  {major}
                </option>
              );
            })}
          </select>
          <select
            name="subject"
            id="category"
            onChange={handleCoursesChange}
            className={"select select-info select-bordered w-full max-w-xs"}
            disabled={!filter.major.selectedMajor}
            value={filter.courses?.selectedCourse}
          >
            <option
              value=""
              disabled
              style={{
                display: "none",
              }}
            >
              Select Course
            </option>
            {filter.courses?.allCouerses?.map((course, index) => (
              <option value={course} key={index}>
                {course}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleSubmit}
          className="btn btn-primary btn-block mt-4"
          disabled={!filter?.college || submitting}
        >
          {submitting ? "searching..." : "Search"}
        </button>
      </div>
      <NavLink to="/new/course" className="btn btn-primary">
        Create New Course
      </NavLink>

      <div className="courses-container">
        {renderedCourses?.map((course, index) => (
          <NavLink
            to={`/courses/${BSON.ObjectId(course._id)}`}
            className="course-card"
            key={index}
            state={course}
          >
            <DisplaySingleCourse course={course} />
          </NavLink>
        ))}
      </div>
      {/* <div className="home-page-footer">
        <Footer />
      </div> */}
    </div>
  );
}
