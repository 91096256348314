import React from "react";
import { useLocation } from "react-router";
import { FaCheck, FaSyncAlt, FaCalculator } from "react-icons/fa";
import { MdOutlineDraw, MdWidthFull } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoIosEyeOff } from "react-icons/io";
import BasicCalculator from "../../components/Calculator";
import TextQ from "./TextQ";
import { Excalidraw } from "@excalidraw/excalidraw";
export default function ExamPage() {
  const allData = useLocation().state;
  const exam = allData?.exam;
  console.log(exam);
  const [tools, setTools] = React.useState({
    calculator: false,
    draw: false,
  });
  const [showTextResults, setShowTextResults] = React.useState(false);
  const [showResults, setShowResults] = React.useState(
    exam.questions.map((question) => false)
  );

  const [examState, setExamState] = React.useState({
    answers: [],
    textAnswers: [],
  });

  function openSketch() {
    setTools({ ...tools, draw: true });
  }

  function closeSketch() {
    setTools({ ...tools, draw: false });
  }
  function OpenCalculator() {
    setTools({ ...tools, calculator: true });
    document.getElementById("calculator").showModal();
  }

  function onChoiseChange(event, index) {
    const answers = examState.answers;
    answers[index] = event.target.value;
    setExamState({ ...examState, answers });
  }

  function handleTextChange(event, index) {
    const textAnswers = examState.textAnswers;
    textAnswers[index] = event.target.value;
    setExamState({ ...examState, textAnswers });
  }

  function showOneResult(e, index) {
    // const rightValue = exam.questions[index].rightAnswer;
    setShowResults((prev) => {
      const newResults = [...prev];
      newResults[index] = !newResults[index];
      return newResults;
    });
  }

  function handleShowAllResults() {
    if (showResults.every((result) => result)) {
      setShowResults(exam.questions.map((question) => false));
      setShowTextResults(false);
      return;
    }
    setShowResults(exam.questions.map((question) => true));
    setShowTextResults(true);
  }

  return (
    <div className="exam-page-container">
      <div
        style={{
          display: tools.draw ? "none" : "block",
        }}
      >
        <h1>{exam.title}</h1>
        <div className="toolbar">
          <button className="toolbar-btn" onClick={handleShowAllResults}>
            {showResults.every((result) => result) ? "Hide" : "Show"} Results
          </button>
          {/* <button className="toolbar-btn">
            <FaSyncAlt />
          </button> */}
          <button className="toolbar-btn" onClick={OpenCalculator}>
            <FaCalculator />
          </button>
          <button className="toolbar-btn" onClick={openSketch}>
            <MdOutlineDraw />
          </button>
        </div>
        <div className="exam-questions">
          {exam.questions.map((question, myIndex) => (
            <div key={myIndex} className="exam-question">
              <div className="question-actions">
                <button
                  className="show-one-result"
                  onClick={(e) => showOneResult(e, myIndex)}
                >
                  {showResults[myIndex] ? <FaEye /> : <IoIosEyeOff />}
                </button>
              </div>
              <h3>{question.question}</h3>
              {!showResults[myIndex] ? (
                <div className="options">
                  {question.options?.map((option, index) => (
                    <label key={index} className="option">
                      <input
                        type="radio"
                        name={`question${myIndex}`}
                        value={option}
                        onChange={(e) => onChoiseChange(e, myIndex)}
                        checked={examState.answers[myIndex] === option}
                      />
                      <span className="radio-text">{option}</span>
                    </label>
                  ))}
                </div>
              ) : (
                <div className="my-show-one-exam-answer">
                  <p className="right-answer-text">
                    Right Answer: {question.answer}
                  </p>
                  <p
                    className={
                      examState.answers[myIndex] === question.answer
                        ? "right-answer-text"
                        : "wrong-answer-text"
                    }
                  >
                    Your Answer: {examState.answers[myIndex]}
                  </p>
                  <p className="score-answer">
                    {examState.answers[myIndex] === question.answer ? "1" : "0"}{" "}
                    / 1
                  </p>
                </div>
              )}
            </div>
          ))}
          {exam.textQuestions.map((question, index) => (
            <TextQ
              key={index}
              question={question}
              index={index}
              onTextChange={handleTextChange}
              showResult={showTextResults}
            />
          ))}
        </div>
      </div>
      <div
        className="sketch-part"
        style={{
          display: tools.draw ? "block" : "none",
          width: "100vw",
          height: "100vh",
          zIndex: 1,
        }}
      >
        <Excalidraw
          initialData={{
            elements: [],
            appState: {
              zenModeEnabled: false,
              viewBackgroundColor: "#ffffff",
            },
            scrollToContent: true,
          }}
          renderTopRightUI={() => {
            return (
              <button className="btn" onClick={closeSketch}>
                Close
              </button>
            );
          }}
        />
      </div>

      <BasicCalculator />
    </div>
  );
}
