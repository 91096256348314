import React from "react";
import "./auth.css";
import { Link } from "react-router-dom";
import loginImage from "../../assets/signup-image.png";
import toast, { Toaster } from "react-hot-toast";
import { authenticateUser } from "../../utils/auth-backend";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Login() {
  const redirect = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const [myParams, setMyParams] = useSearchParams();
  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });
  const myRef = React.useRef(null);

  React.useEffect(() => {
    myRef.current.focus();
    if (myParams.get("message")) {
      toast.error(myParams.get("message"));
    }
  }, []);
  const handleChange = (event) => {
    setLoginData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async function handleAuthentication() {
    setSubmitting(true);
    const { email, password } = loginData;
    if (!email || !password) {
      toast.error("email or password cannot be empty");
      setSubmitting(false);
      return;
    }
    if (!validateEmail(email)) {
      toast.error("email is invalid");
      setSubmitting(false);
      return;
    }

    try {
      const response = await authenticateUser(email, password);
      if (response.status === "error") {
        toast.error(response.error);
        return;
      }
      toast.success(response.message);
      // put the user in the cokee
      console.log(response, "response");
      delete response.status;
      delete response.data.image;
      localStorage.setItem("user", JSON.stringify(response));
      if (myParams.get("sketchData")) {
        redirect(`/view/sketch/${myParams.get("sketchData")}`, {
          replace: true,
        });
        return;
      }
      redirect("/sketch", { replace: true });
    } catch (error) {
      console.log(error);
      toast.error("error authenticating user");
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <div
      className="login-complete-container"
      id={submitting ? "container-submiting" : ""}
    >
      <img src={loginImage} alt="login" className="login-image" />
      <div className="login-page">
        <h1>Login</h1>
        <label className="input input-bordered flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
            <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
          </svg>
          <input
            type="email"
            className="input-login grow"
            placeholder="Email"
            name="email"
            value={loginData.email}
            onChange={handleChange}
            ref={myRef}
          />
        </label>
        <label className="input input-bordered flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
              clipRule="evenodd"
            />
          </svg>
          <input
            type="password"
            className="input-login grow"
            value={loginData.password}
            name="password"
            placeholder="Password"
            onChange={handleChange}
          />
        </label>
        <button
          className="login-button block btn btn-success"
          onClick={handleAuthentication}
        >
          login
        </button>
        <Link to="/signup">Don't have an account? Sign up</Link>
      </div>
      <Toaster />
    </div>
  );
}
