import React from "react";
import { useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink } from "react-router-dom";

export default function SectionCreation() {
  const location = useLocation();
  const course = location.state;
  return (
    <div className="display-course-sections-data" data-theme="cupcake">
      <NavLink to="/courses" className="back-to-courses-link">
        back to all courses
      </NavLink>
      <header className="header-for-courses-section testy">
        <h1 className="course-header">
          {course.courseName}
          <span className="course-price">free</span>
        </h1>
        <div className="creator-container">
          <p className="creator-image">By: {course.creator}</p>
          <div className="course-about-data">
            <p className="course-about">{course.major}</p>
            <p className="course-about">{course.college}</p>
          </div>
        </div>
        <p className="course-description">{course.courseDescription}</p>
        <div className="enroll-course-container">
          <button className="btn enroll-button">Enroll</button>
        </div>
      </header>
    </div>
  );
}
