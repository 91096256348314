const fakeSubjects = {
  Physiology: {
    chapterNames: ["Heart", "Lungs", "Brain", "Kidneys", "Liver"],
    color: "#ff0000",
  },
  Biochemistry: {
    chapterNames: [
      "Cellular Metabolism",
      "Enzymes",
      "Biochemical Pathways",
      "Proteins",
      "Lipids",
    ],
    color: "#00ff00",
  },
  Anatomy: {
    chapterNames: [
      "Skeletal System",
      "Muscular System",
      "Nervous System",
      "Digestive System",
      "Respiratory System",
    ],
    color: "#0000ff",
  },
  Microbiology: {
    chapterNames: ["Bacteria", "Viruses", "Fungi", "Parasites", "Immunology"],
    color: "#ff00ff",
  },
  Pharmacology: {
    chapterNames: [
      "Drug Classes",
      "Pharmacokinetics",
      "Pharmacodynamics",
      "Drug Interactions",
      "Clinical Applications",
    ],
    color: "#ffff00",
  },
};

export { fakeSubjects };
