import {
  createHashRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Navigation from "./pages/Navigation";
import Home from "./pages/Home";
import AllSummary, {
  Loader as AllSummaryLoader,
} from "./pages/summaryPages/AllSummary";
import NewStepOne from "./pages/summaryPages/newStepOne";
import NewStepTwo from "./pages/summaryPages/newStepTwo";
import MarkDownFinallStep from "./pages/summaryPages/MarkDownFinallStep";
import "./App.css";
import Sketch from "./pages/sketch-pages/Sketch";
import AllSketches, {
  loader as allSketchesLoader,
} from "./pages/sketch-pages/AllSketches";
import NewSketchMeta from "./pages/sketch-pages/newSketchMeta";
import UpdateSketchPage from "./pages/sketch-pages/updateSketch";
import Wrapper from "./pages/Wraper";
import Login from "./pages/auth pages/Login";
import { authenticateUser, authorizeUser } from "./utils/auth-backend";
import SignUpWrapper from "./pages/auth pages/signupPages.jsx/signUpWrapper";
import SignUpStepOne from "./pages/auth pages/signupPages.jsx/signUpStepOne";
import SignUpStepTwo from "./pages/auth pages/signupPages.jsx/signUpStepTwo";
import ViewSketch, {
  Loader as viewSketchLoader,
} from "./pages/sketch-pages/ViewSketch";
import Profile, { Loader as ProfileLoader } from "./pages/profile";
import DraggabelResizableComponent from "./components/draggabelResizableComponent";
import ExamMeta from "./pages/exam pages/examMeta";
import AllExams from "./pages/exam pages/AllExams";
import ExamPage from "./pages/exam pages/ExamPage";
import SketchHub from "./pages/sketch-pages/sketchHub";
import CollegeExams from "./pages/exam pages/CollegeExams";
import AllCourses from "./pages/Courses/AllCoursesSection";
import CreateNewCoursePage, {
  loader as createNewCourseLoader,
} from "./pages/Courses/CreateNewCoursePage";
import SectionCreation from "./pages/Courses/sectionCreation";
import UserCreatedCourses, {
  loader as ownUserCoursesLoader,
} from "./pages/Courses/UserCreatedCourses";
import EditUserCourses from "./pages/Courses/editCourses";
import VedioDisplayPage from "./pages/Courses/vedioDisplayPage";
function App() {
  const routes = createHashRouter(
    createRoutesFromElements(
      <Route path="/" element={<Wrapper />}>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route
            path="/summary"
            element={<AllSummary />}
            loader={AllSummaryLoader}
          />
          <Route
            path="/summary/:id"
            element={<div>Summary</div>}
            loader={authorizeUser}
          />
          <Route
            path="/summary/new"
            element={<NewStepOne />}
            loader={authorizeUser}
          />
          <Route
            path="/summary/new/steptwo"
            element={<NewStepTwo />}
            loader={authorizeUser}
          />
          <Route
            path="/summary/new/aiPreview"
            element={<MarkDownFinallStep />}
            loader={authorizeUser}
          />
          <Route
            path="/sketch"
            element={<AllSketches />}
            loader={allSketchesLoader}
          />
          <Route
            path="/new/sketch"
            element={<NewSketchMeta />}
            loader={authorizeUser}
          />
          <Route path="/sketch/hub" element={<SketchHub />} />
          <Route path="exam" element={<AllExams />} />
          <Route path="exam/hub" element={<CollegeExams />} />
          <Route path="/courses" element={<AllCourses />} />
          <Route path="profile" element={<Profile />} loader={ProfileLoader}>
            <Route
              path="courses"
              element={<UserCreatedCourses />}
              loader={ownUserCoursesLoader}
            />
          </Route>
          <Route
            path="/courses/edit/:id"
            element={<EditUserCourses />}
            errorElement={<div>error</div>}
            ErrorBoundary={true}
          />
        </Route>
        <Route path="/courses/:id" element={<SectionCreation />} />
        <Route path="/course/:id/:vedioId" element={<VedioDisplayPage />} />
        <Route
          path="/sketch/board"
          element={<Sketch />}
          loader={authorizeUser}
        />
        <Route
          path="/new/course"
          element={<CreateNewCoursePage />}
          loader={createNewCourseLoader}
        />
        <Route
          path="/view/sketch/:id"
          element={<ViewSketch />}
          loader={viewSketchLoader}
        />
        <Route path="/exam/new" element={<ExamMeta />} />
        <Route path="/exam/solve" element={<ExamPage />} />
        <Route path="/sketch/update/:id" element={<UpdateSketchPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUpWrapper />}>
          <Route index element={<SignUpStepOne />} />
          <Route path="stepTwo" element={<SignUpStepTwo />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={routes} />;
}

export default App;
