import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { Select } from "@mdxeditor/editor";
export default function TodayTodoTaks() {
  // this will be a todo tasks little component which will just save the tasks to local storage and display them
  const colorList = [
    "red",
    "green",
    "blue",
    "yellow",
    "purple",
    "orange",
    "pink",
    "brown",
  ];
  const [todoTasks, setTodoTasks] = React.useState([]);
  const [newTodoTask, setNewTodoTask] = React.useState({
    task: "",
    color: "red",
    StartTime: "00:00",
    EndTime: "00:00",
    TimeZone: false,
  });

  function handleNewTaskChange(e) {
    setNewTodoTask((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handleColorChange(color) {
    setNewTodoTask((prev) => {
      return {
        ...prev,
        color,
      };
    });
  }

  function handleAddingNewTask() {
    if (newTodoTask.StartTime > newTodoTask.EndTime) {
      toast.error("Start Time should be less than End Time");
      return;
    }

    const todoTasks = JSON.parse(localStorage.getItem("todoTasks")) || [];
    const newTodoTasks = [newTodoTask, ...todoTasks];
    localStorage.setItem("todoTasks", JSON.stringify(newTodoTasks));
    setNewTodoTask({
      task: "",
      color: "red",
      StartTime: "00:00",
      EndTime: "00:00",
    });
    setTodoTasks(newTodoTasks);
  }

  function handleDeleteTask(index) {
    const todoTasks = JSON.parse(localStorage.getItem("todoTasks")) || [];
    const newTodoTasks = todoTasks.filter((task, i) => i !== index);
    localStorage.setItem("todoTasks", JSON.stringify(newTodoTasks));
    setTodoTasks(newTodoTasks);
  }
  function handleUpdateTask(index, newTask) {
    const todoTasks = JSON.parse(localStorage.getItem("todoTasks")) || [];
    const newTodoTasks = todoTasks.map((task, i) =>
      i === index ? newTask : task
    );
    localStorage.setItem("todoTasks", JSON.stringify(newTodoTasks));
    setTodoTasks(newTodoTasks);
  }

  React.useEffect(() => {
    const todoTasks = JSON.parse(localStorage.getItem("todoTasks")) || [];
    setTodoTasks(todoTasks);
  }, []);

  if (!todoTasks) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <h1>No Tasks for today</h1>
      </div>
    );
  }
  return (
    <div
      className="todo-task-app-container"
      style={{
        overflowY: "auto",
      }}
    >
      <h1>Todo Tasks</h1>
      <div className="todo-task-input">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="text"
            placeholder="add a task"
            className="input"
            value={newTodoTask.task}
            onChange={handleNewTaskChange}
            name="task"
            style={{
              borderLeft: `5px solid ${newTodoTask.color}`,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>colors</p>
            {colorList?.map((color) => (
              <button
                style={{
                  backgroundColor: color,
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  margin: "5px",
                  width: "10px",
                }}
                key={color}
                onClick={() => handleColorChange(color)}
              ></button>
            ))}
          </div>
          <button
            className="addTimeButton btn"
            onClick={() =>
              setNewTodoTask((prev) => ({ ...prev, TimeZone: !prev.TimeZone }))
            }
          >
            time Interval {newTodoTask.TimeZone ? "on" : "off"}
          </button>
          {newTodoTask.TimeZone && (
            <div className="time-container-todo-app-list">
              <input
                type="time"
                name="StartTime"
                className="time-input input"
                value={newTodoTask.StartTime}
                onChange={handleNewTaskChange}
              />
              <input
                type="time"
                name="EndTime"
                className="time-input input"
                value={newTodoTask.EndTime}
                onChange={handleNewTaskChange}
              />
            </div>
          )}
        </div>
        <button
          className="add-todo-button btn btn-success"
          disabled={newTodoTask.task.length === 0}
          style={{
            marginLeft: "10px",
          }}
          onClick={handleAddingNewTask}
        >
          Add
        </button>
      </div>
      <div className="todo-tasks-list">
        {todoTasks?.map((task, index) => (
          <div
            className="one-todo-task-today"
            style={{
              borderLeft: `5px solid ${task.color}`,
            }}
            key={index}
          >
            <div className="options-buttons-for-tasks">
              <button
                className="delete-button btn btn-ghost"
                onClick={() => handleDeleteTask(index)}
              >
                <MdDelete />
              </button>
              <select className="select-progress select">
                <option value="not-started">Not Started</option>
                <option value="in-progress">In Progress</option>
                <option value="completed">Completed</option>
              </select>
              <button className=" btn btn-ghost">
                <FaEdit />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "5px",
              }}
            >
              <input type="text" className="input" value={task.task} />
              {task.TimeZone ? (
                <p className="time-zoning-the-task">
                  {task.StartTime} - {task.EndTime}
                </p>
              ) : (
                <span className="loading loading-infinity loading-lg"></span>
              )}
            </div>
          </div>
        ))}
      </div>

      <Toaster />
    </div>
  );
}
