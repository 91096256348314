import React from "react";
import { NavLink } from "react-router-dom";
import "./exam.css";
import { BsStars } from "react-icons/bs";
import { FcGraduationCap } from "react-icons/fc";
export default function AllExams() {
  return (
    <div className="all-exams-container">
      <h1>All Your Exams</h1>
      <div className="new-exams-container-links">
        <NavLink to="hub" className="to-new-exam btn btn-outline btn-ghost">
          take exsisting exam <FcGraduationCap />
        </NavLink>
        <NavLink
          to="new"
          className="ai-link-exam to-new-exam btn btn-outline glass"
        >
          create exam by ai <BsStars />
        </NavLink>
      </div>
    </div>
  );
}
