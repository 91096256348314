import React from "react";
import { useLocation, useParams } from "react-router";
import { Excalidraw } from "@excalidraw/excalidraw";
import { FiMaximize2 } from "react-icons/fi";
import { CiMinimize1 } from "react-icons/ci";
import { updateSketchById } from "../../utils/sketch-Backend";
import toast, { Toaster } from "react-hot-toast";

export default function UpdateSketchPage() {
  const [excalidrawAPI, setExcalidrawAPI] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const updateScene = () => {
    const sceneData = {
      elements: [
        {
          type: "rectangle",
          version: 141,
          versionNonce: 361174001,
          isDeleted: false,
          id: "oDVXy8D6rom3H1-LLH2-f",
          fillStyle: "hachure",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 1,
          opacity: 100,
          angle: 0,
          x: 100.50390625,
          y: 93.67578125,
          strokeColor: "#c92a2a",
          backgroundColor: "transparent",
          width: 186.47265625,
          height: 141.9765625,
          seed: 1968410350,
          groupIds: [],
          boundElements: null,
          locked: false,
          link: null,
          updated: 1,
          roundness: {
            type: 3,
            value: 32,
          },
        },
      ],
      appState: {
        viewBackgroundColor: "#edf2ff",
      },
    };
    excalidrawAPI.updateScene(sceneData);
  };
  if (excalidrawAPI) {
    console.log(excalidrawAPI.getSceneElements());
  }
  const sketch = useLocation().state || null;
  const [maximized, setMaximized] = React.useState(false);
  const id = useParams().id;
  const [updatedSketchData, setUpdatedSketchData] = React.useState({
    title: sketch.title || "",
    sketchDescription: sketch.sketchDescription || "",
    mySketch: sketch.mySketch || [],
  });

  function handleTitleChange(e) {
    setUpdatedSketchData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  }

  function handleDescriptionChange(e) {
    setUpdatedSketchData((prev) => ({
      ...prev,
      sketchDescription: e.target.value,
    }));
  }

  function handleMaximize() {
    setMaximized(!maximized);
  }

  async function handleUpdateSketch() {
    setUpdate(true);
    const sketchData = {
      ...updatedSketchData,
      mySketch: excalidrawAPI?.getSceneElements() || [],
    };
    setUpdatedSketchData((prev) => ({
      ...prev,
      mySketch: excalidrawAPI?.getSceneElements() || [],
    }));
    try {
      const reponse = await updateSketchById(id, sketchData);
      console.log(reponse);
      if (reponse.status === "success") {
        toast.success(reponse.message);
      } else {
        toast.error(reponse.message);
      }
    } catch (error) {
      toast.error("Error updating sketch");
    } finally {
      setUpdate(false);
    }
  }
  console.log(updatedSketchData, "updatedSketchData");
  if (!sketch) {
    return <h1>No sketch found</h1>;
  }

  return (
    <div
      className="complete-updated-container"
      id={update ? "container-submiting" : ""}
    >
      {!maximized && (
        <div className="all-inputs-container">
          <div className="input-title-update-container">
            <h1>Title: </h1>
            <input
              type="text"
              value={updatedSketchData.title}
              onChange={handleTitleChange}
              className="input-title-update input"
            />
          </div>
          <div className="text-area-updated-container">
            <h1>Description: </h1>
            <textarea
              value={updatedSketchData.sketchDescription}
              style={{ height: "20vh" }}
              onChange={handleDescriptionChange}
              className="textarea-title-update textarea"
            />
          </div>
        </div>
      )}
      <div
        style={{
          width: maximized ? "100vw" : "80vw",
          height: maximized ? "100vh" : "80vh",
          borderRadius: "10px",
          overflow: "hidden",
          zIndex: 1,
          marginBottom: maximized ? "0px" : "100px",
        }}
        className="excalidraw-container"
      >
        <Excalidraw
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          initialData={{
            elements: sketch.mySketch,
            appState: { zenModeEnabled: false, viewBackgroundColor: "#ffffff" },
          }}
          renderTopRightUI={() => {
            return (
              <>
                <button className="maximizing-button" onClick={handleMaximize}>
                  {maximized ? <CiMinimize1 /> : <FiMaximize2 />}
                </button>
                <button
                  className="btn"
                  onClick={() => handleUpdateSketch(updatedSketchData)}
                >
                  Update
                </button>
              </>
            );
          }}
        />
      </div>
      <Toaster />
    </div>
  );
}
