import * as Realm from "realm-web";
import { connectingToDatabase } from "./sketch-Backend";
import { redirect } from "react-router";

export async function authenticateUser(email, password) {
  const user = await connectingToDatabase();

  try {
    const authenticated = await user.functions.FindUserByEmail(email, password);
    console.log(authenticated, "authenticated user");
    if (authenticated) {
      return {
        status: "success",
        data: authenticated,
        message: "successfully authenticated",
      };
    } else {
      console.log("error authenticating user");
      return {
        status: "error",
        error: "email or password is incorrect",
      };
    }
  } catch (error) {
    return {
      status: "error",
      error: "error authenticating user",
    };
  }
}

export function authorizeUser() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    throw new redirect("/login?message=please login first to view this page");
  }
  return user;
}

export async function noEmailRepeat(email) {
  const user = await connectingToDatabase();
  try {
    const authenticated = await user.functions.check_user_email(email);

    if (authenticated.length > 0) {
      return {
        status: "error",
        error: "email already exists",
      };
    } else {
      return {
        status: "success",
      };
    }
  } catch (error) {
    return {
      status: "error",
      error: "error authenticating user",
    };
  }
}

export async function createUser(data) {
  const user = await connectingToDatabase();
  try {
    const authenticated = await user.functions.addingNewUser(data);

    if (authenticated) {
      return {
        status: "success",
        data: authenticated,
        message: "successfully created user",
      };
    } else {
      return {
        status: "error",
        error: "error creating user",
      };
    }
  } catch (error) {
    return {
      status: "error",
      error: "error creating user",
    };
  }
}

export async function updateUserProfilePicture(picture, id) {
  const user = await connectingToDatabase();
  try {
    const authenticated = await user.functions.updateProfilePicture(
      picture,
      id
    );

    if (authenticated) {
      return {
        status: "success",
        data: authenticated,
        message: "successfully updated profile picture",
      };
    } else {
      return {
        status: "error",
        error: "error updating profile picture",
      };
    }
  } catch (error) {
    return {
      status: "error",
      error: "error updating profile picture",
    };
  }
}
