import * as Realm from "realm-web";

export async function connectingToDatabase() {
  const app = new Realm.App({ id: "application-0-spawq" });
  const credentials = Realm.Credentials.anonymous();
  try {
    const user = await app.logIn(credentials);
    console.log("Successfully logged in!", user.id);
    return user;
  } catch (err) {
    console.error("Failed to log in", err);
  }
}

export async function gettingAllSketches() {
  const checkingInternet = await checkInternetConnection();
  if (!checkingInternet) {
    console.log("no internet");
    return null;
  }
  try {
    const user = await connectingToDatabase();
    const risksPromise = await user.functions.getAllRisks();
    return risksPromise;
  } catch (error) {
    return error;
  }
}

export async function getRiskById(riskId) {
  try {
    const user = await connectingToDatabase();
    const risk = await user.functions.getById(riskId);
    return risk;
  } catch (error) {
    return error;
  }
}

export async function addNewSketch(sketchData) {
  try {
    const user = await connectingToDatabase();
    const risk = await user.functions.addSketshToDb(sketchData);
    return {
      status: "success",
      data: risk,
      message: "successfully added to the database",
    };
  } catch (error) {
    return {
      error: "error adding data to database",
    };
  }
}

async function addNewRiskFromLocalStorage(riskData) {
  try {
    const user = await connectingToDatabase();
    const risk = await user.functions.addNewRisk(riskData);
    return true;
  } catch (error) {
    // return a json with a status code 404 and a message
    console.log("an error have occured");
    return false;
  }
}
export async function getDataForUser(userId) {
  try {
    const user = await connectingToDatabase();
    const sketches = await user.functions.getSketchesForUser(userId);
    return sketches;
  } catch (error) {
    return {
      status: "error",
      error: "error getting data",
    };
  }
}
function validateAllChecked(risks) {
  risks.forEach((risk) => {
    if (risk.checkedTask === false) {
      return false;
    }
  });
}
async function checkInternetConnection() {
  try {
    // Fetch a small, well-known resource with a timeout to ensure the check completes quickly.
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 2000); // 5 seconds timeout

    await fetch("https://www.google.com/favicon.ico", {
      method: "HEAD", // Only request headers to minimize data usage
      signal: controller.signal,
      mode: "no-cors",
    });

    clearTimeout(timeoutId); // Clear the timeout if the fetch succeeds
    return true; // Internet is available
  } catch (error) {
    return false; // Fetch failed, indicating no internet connection
  }
}
export async function updateSketchById(id, newData) {
  try {
    const user = await connectingToDatabase();
    const risk = await user.functions.updateSketchById(id, newData);
    return { status: "success", message: "successfully updated data" };
  } catch (error) {
    return {
      status: "error",
      error: "error updating data",
    };
  }
}

export async function getSketchById(id) {
  try {
    const user = await connectingToDatabase();
    const sketch = await user.functions.getSketchById(id);
    return sketch;
  } catch (error) {
    return {
      status: "error",
      error: "error getting data",
    };
  }
}

export async function handleDeleteSketch(id) {
  try {
    const user = await connectingToDatabase();
    const sketch = await user.functions.deleteSketchById(id);
    return { status: "success", message: "successfully deleted data" };
  } catch (error) {
    return {
      status: "error",
      error: "error deleting data",
    };
  }
}
