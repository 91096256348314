import React from "react";
import { FaEye } from "react-icons/fa";
import { IoIosEyeOff } from "react-icons/io";

export default function TextQ({ question, index, onTextChange, showResult }) {
  const [show, setShow] = React.useState(false);

  function showThisResult() {
    setShow(!show);
  }

  const myRef = React.useRef(null);
  return (
    <div className="question-text-container">
      <button onClick={() => showThisResult()} className="show-result">
        {!(show || showResult) ? <IoIosEyeOff /> : <FaEye />}
      </button>
      <h2>{question.question}</h2>
      {!(show || showResult) ? (
        <div className="options">
          <textarea
            type="text"
            onChange={(e) => onTextChange(e, index)}
            className="textarea-input input   "
            ref={myRef}
          />
        </div>
      ) : (
        <div className="differ-text-answers">
          <p className="right-answer-text m-1">
            Right Answer: {question.answer}
          </p>
          <p
            className={
              question.answer === question.userAnswer
                ? "right-answer-text"
                : "wrong-answer-text"
            }
          >
            Your Answer: {myRef?.current?.value || "No Answer"}
          </p>
        </div>
      )}
    </div>
  );
}
