import {
  NavLink,
  Outlet,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { Drawer } from "vaul";
import { TfiAlignJustify } from "react-icons/tfi";
import Brain from "./sketch-pages/brainIcon.svg";
import { FaHome } from "react-icons/fa";
import { MdSummarize } from "react-icons/md";
import { RiSketching } from "react-icons/ri";
import React from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { PiExam, PiExamLight } from "react-icons/pi";
import { SiCoursera } from "react-icons/si";
import logo from "../assets/iconLogo.png";
export default function Navigation() {
  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem("user");
    navigate("/login?message=you have been logged out");
  }
  const [logoutUser, setLogoutUser] = React.useState(false);
  const [myUser, setMyUser] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const { theme, setTheme } = useOutletContext();
  React.useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setLogoutUser(true);
      setMyUser(user);
      setImage(JSON.parse(user).data?.image || null);
    }
  }, []);

  function handleThemeChange(e) {
    setTheme(e.target.value);
  }

  return (
    <div data-theme={theme} className="full-application-container">
      <div className="main-navbar navbar">
        <div className="navbar-left">
          <h1>Platinum Ai</h1>
          <img
            src="https://th.bing.com/th/id/OIG3.Nmuh76I_YfjAdY9J50rw?pid=ImgGn"
            alt="logo"
            className="logo-image-top-home-page"
          />
        </div>
        <Drawer.Root direction="right">
          {logoutUser && (
            <Drawer.Trigger asChild>
              <button className="btn-square btn m-1">
                <TfiAlignJustify className="text-2xl " />
              </button>
            </Drawer.Trigger>
          )}
          <Drawer.Portal>
            <Drawer.Overlay className="fixed inset-0 bg-black/40 " />
            <Drawer.Content className="my-drawer bg-white flex flex-col rounded-t-[10px] h-full w-[300px] mt-24 fixed bottom-0 right-0">
              <div className="p-4 bg-white flex-1 h-full">
                <div className="max-w-md mx-auto">
                  <Drawer.Title className="font-medium mb-4">
                    <div className="top-navigation-section">
                      <img
                        src={logo}
                        alt="brain"
                        className="navigation-bar-top-image"
                      />
                    </div>
                  </Drawer.Title>
                  <div className="space-y-4">
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        isActive
                          ? "active-navbar navbar-link  text-lg font-medium text-gray-800"
                          : "navbar-link  text-lg font-medium text-gray-800"
                      }
                    >
                      <FaHome /> <span>Home</span>
                    </NavLink>
                    <hr />

                    <NavLink
                      to="/courses"
                      className={({ isActive }) =>
                        isActive
                          ? "active-navbar navbar-link  text-lg font-medium text-gray-800"
                          : "navbar-link  text-lg font-medium text-gray-800"
                      }
                    >
                      <SiCoursera /> <span>Courses</span>
                    </NavLink>
                    <hr />

                    <NavLink
                      to="/summary"
                      className={({ isActive }) =>
                        isActive
                          ? "active-navbar navbar-link  text-lg font-medium text-gray-800"
                          : "navbar-link  text-lg font-medium text-gray-800"
                      }
                    >
                      <MdSummarize /> <span>Summary</span>
                    </NavLink>
                    <hr />
                    <NavLink
                      to="/exam"
                      className={({ isActive }) =>
                        isActive
                          ? "active-navbar navbar-link  text-lg font-medium text-gray-800"
                          : "navbar-link  text-lg font-medium text-gray-800"
                      }
                    >
                      <PiExamLight />
                      <span>Exams</span>
                    </NavLink>
                    <hr />
                    <NavLink
                      to="/sketch"
                      className={({ isActive }) =>
                        isActive
                          ? "active-navbar navbar-link  text-lg font-medium text-gray-800"
                          : "navbar-link  text-lg font-medium text-gray-800"
                      }
                    >
                      <RiSketching /> <span>Sketch</span>
                    </NavLink>
                    <hr />

                    <NavLink
                      to="/login"
                      className={`btn ${
                        logoutUser ? `btn-error ` : ` btn-primary `
                      }btn-error  login-logout-navbar-link navbar-link`}
                    >
                      {logoutUser ? `Logout` : "Login"}
                    </NavLink>
                    {logoutUser ? (
                      <NavLink
                        to="/profile"
                        className={({ isActive }) =>
                          isActive
                            ? "active-navbar navbar-link  text-lg font-medium text-gray-800"
                            : "navbar-link  text-lg font-medium text-gray-800"
                        }
                      >
                        <FaRegUserCircle /> <span>Profile</span>
                      </NavLink>
                    ) : (
                      <NavLink
                        to="/signup"
                        className={({ isActive }) =>
                          isActive
                            ? "active-navbar navbar-link  text-lg font-medium text-gray-800"
                            : "navbar-link  text-lg font-medium text-gray-800"
                        }
                      >
                        Signup
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
        <select
          className="select-theme select"
          name="theme"
          id="theme"
          onChange={handleThemeChange}
          value={theme}
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <option value="cupcake">cupcake</option>
          <option value="bumblebee">bumblebee</option>
          <option value="emerald">emerald</option>
          <option value="corporate">corporate</option>
          <option value="synthwave">synthwave</option>
          <option value="retro">retro</option>
          <option value="cyberpunk">cyberpunk</option>
          <option value="valentine">valentine</option>
          <option value="halloween">halloween</option>
          <option value="garden">garden</option>
          <option value="forest">forest</option>
          <option value="aqua">aqua</option>
          <option value="lofi">lofi</option>
          <option value="pastel">pastel</option>
          <option value="fantasy">fantasy</option>
          <option value="wireframe">wireframe</option>
          <option value="black">black</option>
          <option value="luxury">luxury</option>
          <option value="dracula">dracula</option>
          <option value="cmyk">cmyk</option>
          <option value="autumn">autumn</option>
          <option value="business">business</option>
          <option value="acid">acid</option>
          <option value="lemonade">lemonade</option>
          <option value="night">night</option>
          <option value="coffee">coffee</option>
          <option value="winter">winter</option>
          <option value="dim">dim</option>
          <option value="nord">nord</option>
          <option value="sunset">sunset</option>
        </select>
        {logoutUser ? (
          <>
            <button
              onClick={logout}
              className="my-home-logout-button logout-button btn btn-error"
            >
              logout
            </button>
            <NavLink to="/profile" className="profile-button">
              {image ? (
                <img src={image} alt="profile" className="profile-image" />
              ) : (
                <FaRegUserCircle />
              )}
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              to="/signup"
              className="home-sign-up-button btn btn-outline btn-accent"
            >
              Signup
            </NavLink>
            <NavLink to="/login" className="home-login-button btn btn-primary">
              Login
            </NavLink>
          </>
        )}
      </div>

      <div className="my-outlet-containers container mx-auto">
        <Outlet context={{ theme, myUser }} />
      </div>
    </div>
  );
}
